import { App, DatePicker, Form, Input, InputNumber, Modal, Space } from "antd";
import { CloseSquareFilled, SaveFilled } from '@ant-design/icons';
import { useEffect } from "react";
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";

export const DataEditor = ({isDataEditorOpen, data, dataset, datasetColumnMappings, onDataEditorFinished, onDataEditorCancelled }) => {

    const [form] = Form.useForm();
    const { message } = App.useApp();

    const handleDataEditFinished = async (values) => {
        console.log("handleDataEditFinished");
        var newData = {...data, ...values};
        console.log(newData);
        // get pk column
        var pkColumn = datasetColumnMappings.find((filter) => filter.IsPrimaryKey);
        console.log("pkColumn", pkColumn);
        if(!pkColumn){
            message.error("No Identity Column found");
            return;
        }        
        var pkValue = newData[pkColumn.Name];
        console.log("pkValue", pkValue);
 
        if(pkValue){
            // call api to update data
            const response = await execute('PUT',`${protectedResources.api.baseEndPoint}/datasetData/${dataset.Id}`, {
                data: newData
            });
            if(response.success){
                message.success("Data updated successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        else{
            // call  api to add data
            const response = await execute('POST',`${protectedResources.api.baseEndPoint}/datasetData/${dataset.Id}`, {
                data: newData
            });
            if(response.success){   
                message.success("Data added successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        onDataEditorFinished(newData);
    }

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        onDataEditorCancelled();
    }
    
    useEffect(() => {
        if(isDataEditorOpen){
            form.setFieldsValue(data);
        }
    }, [isDataEditorOpen, data, form]);

    const drawFieldEditor = (column) => {

        switch (column.DataType) {
            case "text":
                return <Input />;
            case "int":
                return <InputNumber />;
            case "datetime":
                return <DatePicker showTime />;
            default:
                return <Input />;
        }
    };

    return (
        <Modal 
            open={isDataEditorOpen} 
            title="Data Editor" 
            okText={<Space><SaveFilled />Save</Space> }
            cancelText={<Space><CloseSquareFilled />Cancel</Space> }
            okButtonProps={{ autoFocus: true, htmlType: 'submit' }} //form: 'dataEditorForm', key: 'submit'
            onCancel={handleDataEditCancelled}
            // onOk={handleDataEditFinished} 
            destroyOnClose
            modalRender={(dom) => (
                <Form
                  layout="horizontal"
                  form={form}
                  name="dataEditorForm"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{
                    modifier: 'public',
                  }}
                  clearOnDestroy
                  onFinish={handleDataEditFinished}
                >
                  {dom}
                </Form>
                
              )}
            >
                {datasetColumnMappings?.filter(c=>c.IsEditable).map((column) => (
                    <Form.Item
                        key={column.Name}
                        label={column.DisplayLabel}
                        name={column.Name}
                        rules={column.Rules ? JSON.parse(column.Rules) : []}
                    >
                        {drawFieldEditor(column)}
                    </Form.Item>
                ))}
        </Modal>
    );

}