import React from 'react';
import styled from 'styled-components';

// Assuming Button and other styled components are defined globally or imported
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  z-index: 2;
  width: 80%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #e55;
  }
`;

const DeleteConfirmationModal = ({ isOpen, onClose, record, onDeleteConfirmed }) => {
  if (!isOpen) return null;

  const handleDelete = () => {
    onDeleteConfirmed(record);
  };

  return (
    <>
      <Overlay onClick={onClose} />
      <Modal>
        <h2>Confirm Changes</h2>
        <p>Are you sure you want to make changes ?</p>
        <ButtonsRow>
          <Button type="button" onClick={handleDelete}>Save Changes</Button>
          <Button type="button" onClick={onClose}>Cancel</Button>
        </ButtonsRow>
      </Modal>
    </>
  );
};

export default DeleteConfirmationModal;
