import { useEffect, useState } from "react";
import ModuleRefreshDataService from "../../services/ModuleRefreshDataService";
import ModuleRefreshWidget from "./components/ModuleRefreshWidget";
import styled from "styled-components";
import { Col, Row } from "antd";

function DataWidgetsSection() {
    const [moduleRefreshData, setModuleRefreshData] = useState([]);
    const fetchModuleRefreshData = async () => {
      const data = await ModuleRefreshDataService.getModuleRefreshData();
      setModuleRefreshData(data);
    }
    useEffect(() => {
      fetchModuleRefreshData();
    }, []);


    return (
        <Container>
            <Row gutter={16}>
                {moduleRefreshData.map((item, index) => (
                    <Col key={index} span={8}>
                        <ModuleRefreshWidget key={index} data={item} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

const Container = styled.div`
    margin-top: 100px;
    width: 100%;
    padding: 0 8px;
`;

export default DataWidgetsSection;