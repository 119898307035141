
import { Card, Col, Row } from "antd";
import styled from "styled-components";
import './ModuleRefreshWidget.css';

const ModuleRefreshWidget = ({ data }) => {

  return (
    <Card title={<CardTitle>{data.title}</CardTitle>} extra={<CardLink href="#">View Details</CardLink>} classNames={{body: 'data-body'}} >
      {data.lastRefresh && (
        <Row>    
          <Col span={12}>
              <p>Last Data Refresh</p>
          </Col> 
          <Col span={12}>
              <p style={{color: data.lastRefreshColor, textAlign: 'right'}}>{data.lastRefresh}</p>
          </Col>
        </Row>
      )}

      {data.nextRefresh && (
        <Row>    
          <Col span={12}>
              <p>Next Data Refresh</p>
          </Col> 
          <Col span={12}>
              <p style={{color: data.lastRefreshColor, textAlign: 'right'}}>{data.nextRefresh}</p>
          </Col>
        </Row>
      )}

    </Card>
  );
};

const CardTitle = styled.h3`
  color: var(--bicollab-primary-color-blue-500, #007ab9);
  text-transform: uppercase;
  font: 400 16px Ubuntu, sans-serif;
`;


const CardLink = styled.a`
  color: var(--bicollab-secondary-color-orange-700, #f16622);
  font-family: Ubuntu, sans-serif;
`;

  export default ModuleRefreshWidget;