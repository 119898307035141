

const QuickTips = () => {

    return (
        <div style={{padding: '20px'}}>
            <p style={{paddingBottom: '15px'}}>Using PowerBI to extract insights from your Human Health Services data can revolutionize how you manage your operations and improve service quality. Here are a few quick tips to help you make the most of the embedded reports in our SaaS application:
            </p>
            <h1 style={{ fontWeight: 'bolder', paddingBottom: '10px'}}>Navigating the Dashboard</h1>
            <p style={{paddingBottom: '15px'}}>                
                When you log into the platform, your dashboard gives you a summary of key metrics at a glance. Use the <b>filters</b> to narrow down specific areas of interest, such as client demographics or service delivery metrics.
            </p>
            <h1 style={{ fontWeight: 'bolder', paddingBottom: '10px'}}>Utilizing Drill-Down Features</h1>
            <p style={{paddingBottom: '15px'}}> Click on any data point in a report to view more granular details. For example, if you are reviewing service utilization, a simple click will break down those numbers by location, service type, or timeframe.
            </p>
            <h1 style={{ fontWeight: 'bolder', paddingBottom: '10px'}}>Exporting and Sharing Reports</h1>
            <p style={{paddingBottom: '15px'}}> All reports can be exported to formats such as Excel or PDF, making it easy to share insights with your team, stakeholders, or board members.
            </p><h1 style={{ fontWeight: 'bolder', paddingBottom: '10px'}}>Interactive Visualizations</h1>
            <p style={{paddingBottom: '15px'}}> PowerBI reports are interactive. Hover over any chart or graph for more detailed insights. Use the <b>zoom</b> and <b>pan</b> functions on visualizations to get a clearer picture of trends or outliers.                
            </p><p style={{paddingBottom: '15px'}}>If you need help navigating these features, our support team is available to provide detailed walkthroughs and training.
            </p>
        </div>
    );
};

export default QuickTips;