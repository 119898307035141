
// import axios from 'axios';

const ModuleRefreshDataService = {
    getModuleRefreshData: async () => {
        // try {
            // const response = await axios.get('https://jsonplaceholder.typicode.com/posts');
            // return response.data;

            return [
                {
                title: "Incident Management",
                lastRefresh: "15 Apr, 2024",
                nextRefresh: "",
                lastRefreshColor: "var(--bicollab-secondary-color-green-700, #50cd14)",
                },
                {
                title: "HRST",
                lastRefresh: "",
                nextRefresh: "28 Apr, 2024",
                lastRefreshColor: "var(--bicollab-primary-color-blue-300, #4a9ecf)",
                },
                {
                title: "Citation Management",
                lastRefresh: "12 Apr, 2024",
                nextRefresh: "",
                lastRefreshColor: "var(--bicollab-secondary-color-green-700, #50cd14)",
                },
            ];

        // } catch (error) {
        //     console.error('Error while fetching data', error);
        // }
    },
};

export default ModuleRefreshDataService;