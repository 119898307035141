import { Button, Table } from "antd";
import { protectedResources } from "config/msalConfig";
import execute from "hooks/execute";
import { useEffect, useState } from "react";
// import CustomerRoleValueData from "../CustomerRoleValues/CustomerRoleValueData";
import { FaEnvelope, FaFilePen, FaPlus, FaTrash } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { DataEditor } from "./DataEditor";
import DeleteConfirmationModal from "../../../components/shared/DeleteConfirmationModal";
import styled from "styled-components";


const RoleValueTable = ({ customer, moduleId }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [data, setData] = useState([]);

    const [editableDataItem, setEditableDataItem] = useState({});
    const [isDataEditorOpen, setIsDataEditorOpen] = useState(false);  
    
    const [currentRecord, setCurrentRecord] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const { orgId } = useParams();




    const openEditorToAdd = () => {
        console.log("openEditorToAdd");
        console.log(customer)
        setEditableDataItem({UserId: customer.UserID,ModuleId: moduleId});
        setIsDataEditorOpen(true);
    };

    const openEditorToEdit = (record) => {
        console.log("edit", record);
        setEditableDataItem(record);
        setIsDataEditorOpen(true);
    };

    const handleDataEditFinished = (newData) => {
        console.log("handleDataEditFinished", newData, editableDataItem);
        setIsDataEditorOpen(false);
        fetchData();
    };

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        setIsDataEditorOpen(false);
    };

    const handleDelete = (record) => {
        setCurrentRecord({ ...record });
        setDeleteModalOpen(true);
    };

    const columns = [
        {
            title: 'Role Value',
            dataIndex: 'RoleValues',
            key: 'RoleValues',
            sorter: (a, b) => a.RoleValues.toString().localeCompare(b.RoleValues.toString())
        },
        {
            title: <Button color="default" variant="text" 
            onClick={ openEditorToAdd } 
            icon={<FaPlus  style={{ color: '#ffad1c' }} />} />,
            key: 'actions',
            className: "text-end-imp",
            render: (text, record) => (
                <>
                    {/* <Button  color="default" variant="text"  title="Resend the invitation" onClick={(e) => {
                        e.stopPropagation();
                        // handleSendInvitation(record);
                    }} icon={<FaEnvelope style={{ color: '#ffad1c' }} />} /> */}
                                            
                    <Button   color="default" variant="text" onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        openEditorToEdit(record);
                    }} icon={<FaFilePen  style={{ color: '#ffad1c' }} />} />
                                        
                    <Button   color="default" variant="text" onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        handleDelete(record);
                    }} icon={<FaTrash style={{ color: '#ffad1c' }} />} />
                </>
                
            ),
        },
    ];

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/customeUserRoles/${customer.UserID}/values`);
            console.log('response', response);
            if (response.success && response.data.length > 0) {
                
                setData(response.data);
                setError('');
            } else {
                setData([]);
                setError(response.message || 'No customers found for the specified organization');
            }
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
       
        console.log(customer, moduleId);
        if(customer){
            fetchData();
        }
    }, [customer, moduleId]);


    // const expandedRowRender = (record) => (
    //     <CustomerRoleValueData customerRole={record} />
    // );

    const handleConfirmDelete = async () => {
        console.log(currentRecord)
        // return;
        try {
            const response = await execute('DELETE', `${protectedResources.api.baseEndPoint}/customeUserRolesValues/${currentRecord.Id}`);
            if (response.success) {
                alert('CustomerRole deleted successfully.');
                await fetchData(); // Refetch data after deletion
            } else {
                setError(response.message || 'Failed to delete user');
            }
        } catch (error) {
            alert('Failed to delete user: ' + error.message);
        }
        setDeleteModalOpen(false);
    };

    return (
        <>
        <Table 
            rowKey={record => record.Id} 
            columns={columns} 
            dataSource={data}
            loading={loading}
            // expandable={{
            //     expandedRowRender,
            //     defaultExpandedRowKeys: ['0'],
            // }}  
            pagination={false} />
            { isDataEditorOpen ? 
                <DataEditor
                    customer={customer}
                    isOpen={isDataEditorOpen} 
                    dataItem={editableDataItem} 
                    onDataEditorFinished={handleDataEditFinished} 
                    onDataEditorCancelled={handleDataEditCancelled} /> 
                    : null }
            {isDeleteModalOpen && <DeleteConfirmationModal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)} record={currentRecord} onDeleteConfirmed={handleConfirmDelete} />}
            </>
    );

};

const TableContainer = styled.div`
    border-radius: 0px;
    margin: 20px;
    margin-top:-20px;
    width: 125%;
    margin-left: -150px;
    // max-height: 350px; /* Set maximum height as desired */
    // overflow-y: auto; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

export default RoleValueTable;