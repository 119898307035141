import {
    useState,
    useCallback,
} from 'react';

import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { msalInstance } from '../index';
import { protectedResources } from '../config/msalConfig';

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest 
 * @returns 
 */
const useFetchWithMsal = () => {    
    const [isLoading, setIsLoading] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const [data, setData] = useState(null);
    /**
     * Execute a fetch request with the given options
     * @param {string} method: GET, POST, PUT, DELETE
     * @param {String} endpoint: The endpoint to call
     * @param {Object} data: The data to send to the endpoint, if any 
     * @returns JSON response
     */
    const execute = async (method, endpoint, data = null) => {
        console.log('Executing fetch with MSAL', method, endpoint, data);
        const account = msalInstance.getActiveAccount();
        if(!account){
            throw Error('No active account found');
        }
        
        var authObject = {
            scopes: protectedResources.api.scopes.read,
            account: msalInstance.getActiveAccount(),
            redirectUri: '/redirect',
        };

        const result = await msalInstance.acquireTokenSilent({
            ...authObject,
            account: account,
        });

        try {
            let response = null;

            const headers = new Headers();
            const bearer = `Bearer ${result.accessToken}`;            
            headers.append("Authorization", bearer);

            if (data) headers.append('Content-Type', 'application/json');

            let options = {
                method: method,
                headers: headers,
                body: data ? JSON.stringify(data) : null,
            };

            setIsLoading(true);

            response = await (await fetch(endpoint, options)).json();
            console.log(response);
            setData(response);

            setIsLoading(false);
            return response;
        } catch (e) {
            setFetchError(e);
            setIsLoading(false);
            throw e;
        }
        
    };

    return {
        isLoading,
        fetchError,
        execute: useCallback(execute,[1]), // to avoid infinite calls when inside a `useEffect`
    };
};

export default useFetchWithMsal;