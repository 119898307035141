import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";
import { loginRequest, msalConfig } from './config/msalConfig';
import App from './App';
import UserDataService from './services/localStorageService';

/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
export const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {    
    //console.log(event);
    // if (
    //     (event.eventType === EventType.LOGIN_SUCCESS ||
    //         event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    //         event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    //     event.payload.account ) 
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) 
    {
        msalInstance.setActiveAccount(event.payload.account);
    }
    if(event.eventType === EventType.SSO_SILENT_FAILURE) {
        UserDataService.clearUserData(); // Clear user data from local storage
        msalInstance.logoutRedirect({ postLogoutRedirectUri: window.location.origin });
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
        <BrowserRouter>
            <App instance={msalInstance} />
        </BrowserRouter>
    // </React.StrictMode>
);
