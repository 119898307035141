import React, { useState,useEffect,useMemo } from 'react';

import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import execute from '../hooks/execute';
import { protectedResources } from '../config/msalConfig';

function Citation() { 
  return (
    <Columnn>
      <NewCitation />
      <NavigationBar/>
      <AuditForm/>
    </Columnn>
  );
}

const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  width: 110%;
  gap: 20px; // Adjust the vertical gap between MyComponent1 and MyComponent3
`;



function NewCitation() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { userDetails } = useUser();
  
    const toggleModal = () => {
      setModalIsOpen(!modalIsOpen);
    };
  
    return (
      <div>
        <Header1>
          <Title>New Citation</Title>
          {/* <NewOrganizationButton onClick={toggleModal}>New User</NewOrganizationButton> */}
  
        </Header1>
              {/* <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} userEmail={userDetails.email}  /> */}
  
      </div>
    );
  }
  
  
  const Header1 = styled.header`
  display: flex;
  padding: 10px; // Adjusted padding for the header
  margin-right: -100px; // Ensure this is actually needed, otherwise remove
  margin-top: 20px;
  margin-left:-170px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  `;
  
  
  const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-primary-color-blue-900, #002c57);
  `;
  
  const NewCitation1 = styled.button`
  padding: 10px 18px;
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-black-white-white, #fff);
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 790px;
  `;

const NavigationItem = ({ text, isActive, onClick }) => (
    <NavItem isActive={isActive} onClick={onClick}>
      {text}
    </NavItem>
  );
  
  // Navigation bar component with dynamic active state management
  function NavigationBar() {
    const [activeIndex, setActiveIndex] = useState(0); // State to track the active item
    const [displayText, setDisplayText] = useState(""); // State to track text to display when item is clicked
    const navigate = useNavigate();
  
    const navItems = [
      { text: "Citation", route: "/citation" },
      { text: "Provider Plan of Action", route: "/provider-plan" },
      { text: "Additional Corrective Actions", route: "/additional-action" },
      { text: "Review",route: "/review" },    ];
  
    const handleItemClick = (index, text, route) => {
      setActiveIndex(index);
      setDisplayText(`You clicked on: ${text}`);
      navigate(route);
    };
  
    return (
      <SearchContainer>
        <nav className="navigation-bar">
          {navItems.map((item, index) => (
            <NavigationItem
              key={index}
              text={item.text}
              isActive={index === activeIndex}  // Determine if the item is active based on index
              onClick={() => handleItemClick(index, item.text, item.route)}
            />
          ))}
        </nav>
        <div>{displayText}</div> {/* Display text area */}
      </SearchContainer>
    );
  }
  
  const SearchContainer = styled.section`
  border-radius: 3px;
  background-color: var(--bicollab-black-white-black-100, #f2f2f2);
  display: flex;
  gap: 20px;
  margin-top:-20px;
  margin-left: -160px;
  // margin-right: -120px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  padding: 6px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;


const NavItem = styled.button`
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  border-radius: 4px; // Rounded corners for a smooth box appearance
  background-color: var(--bicollab-black-white-white, #fff); // Light background
  color: ${props => props.isActive ? 'var(--bicollab-secondary-color-orange-700, #f16622)' : 'var(--bicollab-black-white-black-500, #515151)'};
  padding: 10px 15px; // Slightly increased padding for better visual spacing
  border: 2px solid var(--bicollab-black-white-grey-300, #ddd); // Visible border to emphasize the box
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1); // Subtle shadow for a 3D effect
  cursor: pointer;
  transition: all 0.3s ease; // Smooth transition for hover effects
  margin-right: 10px;
  &:hover {
    background-color: var(--bicollab-grey-200, #f7f7f7); // Change background on hover
    box-shadow: 0px 4px 8px rgba(0,0,0,0.15); // Increase shadow on hover for a "lifted" effect
  }

  @media (max-width: 991px) {
    white-space: normal; // Ensures text wraps properly in smaller screens
  }
`;

  const StyledNavigationBar = styled.nav`
    border-radius: 3px;
    background-color: var(--bicollab-black-white-black-100, #f2f2f2);
    display: flex;
    gap: 12px;
    padding: 6px 14px;
  
    @media (max-width: 991px) {
      flex-wrap: wrap;
      padding-right: 20px;
    }
  `;



  const FormField = ({ label, placeholder, type = 'text' }) => (
    <FieldWrapper>
      <FieldLabel>{label}</FieldLabel>
      <FieldInput
        type={type}
        placeholder={placeholder}
        aria-label={label}
      />
    </FieldWrapper>
  );
  
  const SelectField = ({ label, options }) => (
    <FieldWrapper>
      <FieldLabel>{label}</FieldLabel>
      <SelectWrapper>
        <SelectInput>
          <option value="">Select</option>
          {options.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </SelectInput>
        <SelectIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4e355ff160bac1d9944e0755c79365ca97cb1a508c2fbebb93313db8081fe3d?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
      </SelectWrapper>
    </FieldWrapper>
  );
  
  const TextAreaField = ({ label, placeholder }) => (
    <FieldWrapper>
      <FieldLabel>{label}</FieldLabel>
      <TextArea placeholder={placeholder} aria-label={label} />
    </FieldWrapper>
  );
  
//     return (
//       <FormContainer>
//         <FormSection>
//           <SectionTitle>Primary Information</SectionTitle>
//           <FieldGroup>
//             <SelectField label="Audit Date" options={[]} />
//             <SelectField label="Audit Agency" options={[]} />
//             <SelectField label="Audit Type" options={['Licensing']} />
//             <SelectField label="Status" options={['In Process']} />
//           </FieldGroup>
//           <FieldGroup>
//             <FormField label="Reviewer" placeholder="Enter a name or email address" />
//             <FormField label="Additional Reviewer" placeholder="Enter a name or email address" />
//           </FieldGroup>
//         </FormSection>
  
//         <Divider />
  
//         <FormSection>
//           <SectionTitle>Secondary Information</SectionTitle>
//           <FieldGroup>
//             <SelectField label="Citation Type" options={['Individual Record']} />
//             <FormField label="Customer Name" placeholder="Enter a name or email address" />
//             <SelectField label="Location" options={['0040 - 831 Bower Hill, California, USA']} />
//           </FieldGroup>
//           <InfoText>Input the individual who's record is being reviewed, if applicable</InfoText>
//         </FormSection>
  
//         <Divider />
  
//         <FormSection>
//           <SectionTitle>Additional Information</SectionTitle>
//           <FieldGroup>
//             <SelectField label="Regulation Number - Desc" options={[]} />
//             <SelectField label="Corrective Action Type" options={[]} />
//             <SelectField label="Corrective Action Category" options={[]} />
//             <SelectField label="Corrective Action Due Date" options={[]} />
//           </FieldGroup>
//           <FieldGroup>
//             <FormField label="Responsible Party" placeholder="Enter a name or email address" />
//             <SelectField label="Corrective Action Completion Date" options={[]} />
//           </FieldGroup>
//           <FieldGroup>
//             <TextAreaField label="Describe Citation/Violation of Regulation" placeholder="Type in" />
//             <TextAreaField label="Corrective Action Required By Inspector" placeholder="Type in" />
//           </FieldGroup>
//           <TextAreaField label="Comments/Follow-up Comments" placeholder="Type in" />
//         </FormSection>
  
//         <FormActions>
//           <CancelButton>Cancel</CancelButton>
//           <SaveButton>Save</SaveButton>
//           <ProviderPlanButton>
//             Provider Plan Of Action
//             <ActionIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/11a66aa01c9ed3d3ab706b1c18a13560e6d5bb36a3cd8d5d4aeb69e78f7aa86b?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
//           </ProviderPlanButton>
//         </FormActions>
//       </FormContainer>
//     );
//   };
const AuditForm = () => {
    const [formData, setFormData] = useState({
      auditDate: '',
      auditAgency: '',
      auditType: 'Licensing',
      status: 'In Process',
      reviewer: '',
      additionalReviewer: '',
      citationType: 'Individual Record',
      customerName: '',
      location: '0040 - 831 Bower Hill, California, USA',
      regulationNumberDesc: '',
      correctiveActionType: '',
      correctiveActionCategory: '',
      correctiveActionDueDate: '',
      responsibleParty: '',
      correctiveActionCompletionDate: '',
      describeCitation: '',
      correctiveActionRequired: '',
      comments: ''
    });
  
    
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
    const handleSubmit = (e) => {
      e.preventDefault(); // Prevent default form submission
      // Send formData to the backend
      console.log(formData);
    };
  
    return (
      <FormContainer onSubmit={handleSubmit}>
        <FormSection>
          <SectionTitle>Primary Information</SectionTitle>
          <FieldGroup>
            <SelectField
              label="Audit Date"
              name="auditDate"
              options={['Option 1', 'Option 2']}
              value={formData.auditDate}
              onChange={handleChange}
            />
            <SelectField
              label="Audit Agency"
              name="auditAgency"
              options={['Agency 1', 'Agency 2']}
              value={formData.auditAgency}
              onChange={handleChange}
            />
            <SelectField
              label="Audit Type"
              name="auditType"
              options={['Licensing']}
              value={formData.auditType}
              onChange={handleChange}
            />
            <SelectField
              label="Status"
              name="status"
              options={['In Process']}
              value={formData.status}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup>
            <FormField
              label="Reviewer"
              name="reviewer"
              placeholder="Enter a name or email address"
              value={formData.reviewer}
              onChange={handleChange}
            />
            <FormField
              label="Additional Reviewer"
              name="additionalReviewer"
              placeholder="Enter a name or email address"
              value={formData.additionalReviewer}
              onChange={handleChange}
            />
          </FieldGroup>
        </FormSection>
  
        <Divider />
  
        <FormSection>
          <SectionTitle>Secondary Information</SectionTitle>
          <FieldGroup>
            <SelectField
              label="Citation Type"
              name="citationType"
              options={['Individual Record']}
              value={formData.citationType}
              onChange={handleChange}
            />
            <FormField
              label="Customer Name"
              name="customerName"
              placeholder="Enter a name or email address"
              value={formData.customerName}
              onChange={handleChange}
            />
            <SelectField
              label="Location"
              name="location"
              options={['0040 - 831 Bower Hill, California, USA']}
              value={formData.location}
              onChange={handleChange}
            />
          </FieldGroup>
          <InfoText>Input the individual who's record is being reviewed, if applicable</InfoText>
        </FormSection>
  
        <Divider />
  
        <FormSection>
          <SectionTitle>Additional Information</SectionTitle>
          <FieldGroup>
            <SelectField
              label="Regulation Number - Desc"
              name="regulationNumberDesc"
              options={['Option 1', 'Option 2']}
              value={formData.regulationNumberDesc}
              onChange={handleChange}
            />
            <SelectField
              label="Corrective Action Type"
              name="correctiveActionType"
              options={['Type 1', 'Type 2']}
              value={formData.correctiveActionType}
              onChange={handleChange}
            />
            <SelectField
              label="Corrective Action Category"
              name="correctiveActionCategory"
              options={['Category 1', 'Category 2']}
              value={formData.correctiveActionCategory}
              onChange={handleChange}
            />
            <SelectField
              label="Corrective Action Due Date"
              name="correctiveActionDueDate"
              options={['Date 1', 'Date 2']}
              value={formData.correctiveActionDueDate}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup>
            <FormField
              label="Responsible Party"
              name="responsibleParty"
              placeholder="Enter a name or email address"
              value={formData.responsibleParty}
              onChange={handleChange}
            />
            <SelectField
              label="Corrective Action Completion Date"
              name="correctiveActionCompletionDate"
              options={['Date 1', 'Date 2']}
              value={formData.correctiveActionCompletionDate}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup>
            <TextAreaField
              label="Describe Citation/Violation of Regulation"
              name="describeCitation"
              placeholder="Type in"
              value={formData.describeCitation}
              onChange={handleChange}
            />
            <TextAreaField
              label="Corrective Action Required By Inspector"
              name="correctiveActionRequired"
              placeholder="Type in"
              value={formData.correctiveActionRequired}
              onChange={handleChange}
            />
          </FieldGroup>
          <TextAreaField
            label="Comments/Follow-up Comments"
            name="comments"
            placeholder="Type in"
            value={formData.comments}
            onChange={handleChange}
          />
        </FormSection>
  
        <FormActions>
          <CancelButton type="button">Cancel</CancelButton>
          <SaveButton onClick={handleSubmit} >Save</SaveButton>
          <ProviderPlanButton type="button">
            Provider Plan Of Action
            <ActionIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/11a66aa01c9ed3d3ab706b1c18a13560e6d5bb36a3cd8d5d4aeb69e78f7aa86b?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
          </ProviderPlanButton>
        </FormActions>
      </FormContainer>
    );
  };
  

  const FormContainer = styled.form`
    border-radius: 2px;
    // display: flex;
    flex-direction: column;
    padding: 17px 11px;
    margin-left:-150px;
    margin-top:-8px;
    margin-right: -120px;
    overflow-y: auto; // Enables vertical scrolling if content overflows
    max-height: 65vh; // Sets a maximum height (you can adjust this based on your needs)

  `;
  
  const FormSection = styled.section`
    margin-top: 24px;
  `;
  
  const SectionTitle = styled.h2`
    color: #002c57;
    letter-spacing: 0.4px;
    margin-top:-0px;
    font: 500 14px/114% Ubuntu, sans-serif;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const FieldGroup = styled.div`
    display: flex;
    margin-top: 20px;
    gap: 20px;
    justify-content: space-between;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  `;
  
  const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
  `;
  
  const FieldLabel = styled.label`
    color: #767676;
    text-align: center;
    font: 500 14px Ubuntu, sans-serif;
  `;
  
  const FieldInput = styled.input`
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #bbbbbc;
    background-color: #fff;
    margin-top: 13px;
    color: #767676;
    padding: 13px 21px;
    font: 400 16px Ubuntu, sans-serif;
    @media (max-width: 991px) {
      padding: 13px 20px;
    }
  `;
  
  const SelectWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
  `;
  
  const SelectInput = styled.select`
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #bbbbbc;
    background-color: #fff;
    margin-top: 13px;
    padding: 10px 21px;
    font: 400 16px Ubuntu, sans-serif;
    appearance: none;
    width: 100%;
    @media (max-width: 991px) {
      padding: 10px 20px;
    }
  `;
  
  const SelectIcon = styled.img`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  `;
  
  const TextArea = styled.textarea`
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #bbbbbc;
    background-color: #fff;
    margin-top: 13px;
    color: #bbbbbc;
    padding: 19px 21px;
    font: 400 16px Ubuntu, sans-serif;
    @media (max-width: 991px) {
      padding: 19px 20px;
    }
  `;
  
  const Divider = styled.hr`
    width: 100%;
    border: none;
    border-top: 1px solid #767676;
    margin: 10px 0;
  `;
  
  const InfoText = styled.p`
    color: #515151;
    margin-top: 20px;
    font: 400 16px Ubuntu, sans-serif;
  `;
  
  const FormActions = styled.div`
    display: flex;
    margin-top: 58px;
    gap: 13px;
    @media (max-width: 991px) {
      flex-wrap: wrap;
      margin-top: 40px;
    }
  `;
  
  const ActionButton = styled.button`
    font: 500 20px Ubuntu, sans-serif;
    border-radius: 50px;
    padding: 10px 18px;
    cursor: pointer;
    @media (max-width: 991px) {
      padding: 10px 20px;
    }
  `;
  
  const CancelButton = styled(ActionButton)`
    color: #f16622;
    background-color: #fff;
    border: 1px solid #f16622;
  `;
  
  const SaveButton = styled(ActionButton)`
    color: #fff;
    background-color: #f16622;
    border: none;
  `;
  
  const ProviderPlanButton = styled(ActionButton)`
    display: flex;
    align-items: center;
    gap: 10px;
    color: #f16622;
    background-color: #fff;
    border: 1px solid #f16622;
  `;
  
  const ActionIcon = styled.img`
    width: 20px;
    height: 20px;
  `;
  
  
export default Citation;