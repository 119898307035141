import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import styled from 'styled-components';

function Report(props) {
    const location = useLocation();
    const { embedUrl, accessToken, isEmbedToken } = location.state || {};
    const { reportId } = useParams();

    useEffect(() => {
        if (!reportId || !embedUrl || !accessToken || isEmbedToken === undefined) {
            console.error('Missing report details');
        }
        console.log(`Report details: ${reportId}, ${embedUrl}, ${accessToken}, ${isEmbedToken}`);
    }, [reportId, embedUrl, accessToken, isEmbedToken]);

    return (
        <Columnn>
            <ReportContainer>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        id: reportId,
                        embedUrl: embedUrl,
                        accessToken: accessToken,
                        tokenType: isEmbedToken === true ? models.TokenType.Embed : models.TokenType.Aad,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: true,
                                    visible: true
                                }
                            },
                            background: models.BackgroundType.Transparent,
                        }
                    }}
                    eventHandlers={new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log('Report Error:', event.detail); }],
                    ])}
                    cssClassName="reportClass"
                    getEmbeddedComponent={(embeddedReport) => {
                        console.log('Embedded report object:', embeddedReport);
                    }}
                />
            </ReportContainer>
        </Columnn>

    );
}

const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px; // Adjust the vertical gap between MyComponent1 and MyComponent3  
`;
const ReportContainer = styled.div`
    width: 100%;
    // height: 80%;
    display: flex;
    margin-top: 28px;
    
    justify-content: center;
    align-items: flex-end;

    .reportClass {
        width: 100%;
        height: 80vh;
        border-radius: 0 !important;
    }
    .reportClass iFrame {
        border: none;
    }
`;


export default Report;

