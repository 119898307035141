import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from 'components/UserContext';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import execute from 'hooks/execute';
import { protectedResources } from 'config/msalConfig';
import { Menu } from 'antd';
import { FaChartLine, FaComments, FaDatabase, FaTableCellsLarge, FaUsers } from 'react-icons/fa6';
import * as fa6 from 'react-icons/fa6';

const generalItems =  [
    {
        key: 'general-group',
        label: 'General',
        type: 'group',
        children: [
        {
            key: "faq",
            label: "FAQ",
            icon: <FaComments alt='FAQ' /> 
        }]
    }
];

const defaultItems = [
    {
        key: "dashboard",
        label: "Dashboard",
        icon: <FaTableCellsLarge alt='Dashboard' /> 
    }    
];

function Sidebar() {
    const navigate = useNavigate();
    const { userDetails } = useUser();
    const [menuItems, setMenuItems] = useState([...defaultItems, ...generalItems]);
    const [adminMenus, setAdminMenus] = useState(null);
    const [selectedMenuKey, setSelectedMenuKey] = useState('dashboard');
    const [openMenuKeys, setOpenMenuKeys] = useState([]);

    const [reports, setReports] = useState([]);    
    const location = useLocation();
    const { hash, pathname, search } = location;

    const fetchReports = () => {
        if(userDetails && userDetails.IsOrgUser===false){
            //console.log("while fetching get-all-reports", userDetails);
            execute('POST',`${protectedResources.api.baseEndPoint}/pbi/get-all-reports`)
            .then(response => {
                //console.log('Fetched all reports:', response); // Debug log
                var fetchedReports = response.data
                    .filter(r=>r.embedToken)
                    .map((r)=>({...r, module: r.name.split('-')[0]?.trim(),  name: r.name.split('-')[1]?.trim()}));
                //console.log('reports:', fetchedReports); // Debug log
                setReports(fetchedReports);
            })
            .catch(error => console.error('Failed to fetch all reports:', error));            
        }
    };

    const loadAdminMenus = () => {
        var adminMenus = [];
        if(userDetails?.IsAdmin){
            adminMenus = {
                    key: 'admin-group',
                    label: 'Admin',
                    type: 'group',
                    children: [
                        {
                            label: "User Management",
                            key: "UM",
                            icon: <FaUsers alt='User Management' />,
                        },
                        {
                            label: "Data Administration",
                            key: "DA",
                            icon: <FaDatabase alt='Data Administration' />,
                        }
                    ]
                };
            setAdminMenus(adminMenus);
            setMenuItems([...defaultItems, adminMenus, ...generalItems]);
        }
    };

    const setCurrentMenuActive = () => {
        if(pathname.startsWith('/report/')){
            const parts = pathname.split('/');
            //console.log('setCurrentMenuActive:', parts);
            if(parts.length >= 3){
                 const moduleShortName = parts[2];
                const reportId = parts[3];
                //console.log('setCurrentMenuActive:', moduleShortName, reportId);
                setOpenMenuKeys([moduleShortName]);
                setSelectedMenuKey(reportId);
                return;
            }
        }
        switch(pathname){   
            case "/dashboard":
                setSelectedMenuKey('dashboard');
                break;
            case "/userManage":
                setSelectedMenuKey('UM');
                break;
            case "/data-administration":
                setSelectedMenuKey('DA');
                break;
            case "/faq":
                setSelectedMenuKey('faq');
                break;
            default:                
                //console.log('default:', pathname, location);
                break;
        }
    };

    useEffect(() => {
        setMenuItems([...defaultItems, ...generalItems]);
        loadAdminMenus();
        fetchReports();
        setCurrentMenuActive();
    }, [userDetails]);

    const getIcon = (Icon) => (<Icon />);


    useEffect(() => {
        if(reports.length > 0 && userDetails?.Modules){
            const moduleMenus = {
                key: 'module-group',
                label: 'Modules',
                type: 'group',
                children: userDetails.Modules.map(module => {
                    const moduleReports = reports.filter(r=>r.module === module.ShortName);
                    //console.log("moduleReports:", module.ShortName, moduleReports, reports);
                    if(moduleReports.length === 0){
                        return null;
                    }
                    return {
                        label: module.ModuleName,
                        key: module.ShortName,
                        // icon: <module.Icon alt={module.ModuleName} /> ,
                        icon: getIcon(fa6[module.Icon]),
                        children: moduleReports.map(report => {
                            return {
                                label: report.name,
                                key: report.id,
                                icon: <FaChartLine alt={report.name} />,
                                onClick: () => handleReportClick(report.id, report.embedUrl, report.embedToken, report.isEmbedToken, report.module)
                            };
                        })
                    };
                }).filter(module => module !== null)
            };
            setMenuItems([...defaultItems, adminMenus, moduleMenus, ...generalItems]);            
        }
    }, [userDetails, reports]);

    const handleReportClick = (reportId, embedUrl, accessToken, isEmbedToken, moduleShortName) => {
        //console.log('before calling report', reportId, embedUrl, accessToken, isEmbedToken);
        setSelectedMenuKey(reportId);
        navigate(`/report/${moduleShortName}/${reportId}`, { state: { embedUrl, accessToken, isEmbedToken } });
    };

    const onMenuItemClicked = (e) => {
        //console.log('menu item clicked:', e);
        switch(e.key){
            case "dashboard":
                setSelectedMenuKey('dashboard');
                navigate('/');
                break;
            case "UM":
                setSelectedMenuKey('UM');
                navigate('/userManage');
                break;
            case "DA":
                setSelectedMenuKey('DA');
                navigate('/data-administration');
                break;
            case "faq":
                setSelectedMenuKey('faq');
                navigate('/faq');
                break;
            // case "CM":
            //     navigate('/inspection');
            //     break;
            // case "Citation":
            //     navigate('/citation-management');
            //     break;
            // case "Inspection":
            //     navigate('/inspection');
            //     break;
            default:
                break;
        }
    };
    return (
        <SidebarWrapper>
            <SidebarContainer>
                <SidebarHeader>
                    <SidebarLogo src="https://birddata.blob.core.windows.net/b2clogin/cust-app/images/prism-logo.png" alt="Prism" />
                    <SidebarTitle>Prism</SidebarTitle>
                </SidebarHeader>
                <Menu 
                    items={ menuItems }
                    mode="inline"
                    //style={{ width: 300 }}
               //     openKeys={openMenuKeys}
                    selectedKeys={[selectedMenuKey]}
                    defaultSelectedKeys={['dashboard']}
                    //defaultOpenKeys={openMenuKeys}
                    onClick={onMenuItemClicked} />
            </SidebarContainer>
        </SidebarWrapper>
    );
}



const SidebarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: var(--bicollab-black-white-black-500, #515151);
    font-weight: 500;
    height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: auto;
    width: 300px;
`;

const SidebarContainer = styled.aside`
    border-radius: 8px;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(255, 255, 255, 1);
    background-color: var(--bicollab-black-white-black-50, #fcfcfc);
    display: flex;
    flex-direction: column;
    min-width: 30px;
    margin-top: 2px;
    // padding: 16px 20px;
    // margin-top: 30px;
    // margin-bottom: 30px;
    min-height: calc(100vh - 155px);
`;

const SidebarHeader = styled.header`
    color: #002c57;
    display: flex;
    font-size: 20px;
    gap: 9px;
`;

const SidebarLogo = styled.img`
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 53px;
`;

const SidebarTitle = styled.h1`
    font-family: Ubuntu, sans-serif;
    margin: auto 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
`;

export default Sidebar;
