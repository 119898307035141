import { Button, message, Space, Table, Input } from "antd";
import { useEffect, useState, useRef } from "react";
import {  EditFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import qs from 'qs';
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";
import "./DataManager.css";
import { DataEditor } from "./DataEditor";
import Highlighter from 'react-highlight-words';


const getTableParams = (params) => ({
    pS: params.pagination?.pageSize,
    pN: params.pagination?.current,
    sF: params.sortField,
    sO: params.sortOrder,
    f: params.filters
    //...params,
  });

export const DataManager = ({ dataset, datasetColumnMappings }) => {   
    const [columns, setColumns] = useState([]); 
    const [primaryKeyColumnName, setPrimaryKeyColumnName] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });

    console.log("datasetColumnMappings////////")
    console.log(datasetColumnMappings)
    const [isDataEditorOpen, setIsDataEditorOpen] = useState(false);
    const [editableDataItem, setEditableDataItem] = useState({});

    const handleDataEditFinished = (newData) => {
        console.log("handleDataEditFinished", newData, editableDataItem, primaryKeyColumnName, data);
        setIsDataEditorOpen(false);
        fetchData();
        // if(editableDataItem[primaryKeyColumnName]){
        //     console.log("handleDataEditFinished Updated");
        //     setData(data.map((item) => item[primaryKeyColumnName] === newData[primaryKeyColumnName] ? newData : item));
        // }else{
        //     console.log("handleDataEditFinished Added");
        //     setData([newData, ...data]);
        // }
        // console.log("handleDataEditFinished data", data);
    };

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        setIsDataEditorOpen(false);
    };

    const openDatasetDataEditorToAdd = () => {
        console.log("openEditorToAdd");
        setEditableDataItem({});
        setIsDataEditorOpen(true);
    };

    const openDatasetDataEditorToEdit = (record) => {
        console.log("edit", record);
        setEditableDataItem(record);
        setIsDataEditorOpen(true);
    };

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = async (selectedKeys, confirm, dataIndex) => {
        console.log(dataIndex)
        
        // await fetchAllData()
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Search
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({
                    closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
              >
                close
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#1677ff' : undefined,
            }}
          />
        ),
        onFilter: (value, record) =>
          record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        filterDropdownProps: {
          onOpenChange(open) {
            if (open) {
              setTimeout(() => searchInput.current?.select(), 100);
            }
          },
        },
        render: (text) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    useEffect(() => {
        if(datasetColumnMappings && datasetColumnMappings.length > 0){
            //setColumns([]);
            //setData([]);    
            console.log("datasetColumnMappings", datasetColumnMappings);    
            var pkColumn = datasetColumnMappings.find((filter) => filter.IsPrimaryKey);
            console.log("pkColumn", pkColumn);
            const pkColumnName = pkColumn ? pkColumn.Name : '';
            setPrimaryKeyColumnName(pkColumnName);
            setColumns([ 
                ...datasetColumnMappings.filter(c=>c.IsDisplayable).map((columnMapping) => ({ 
                    title: columnMapping.DisplayLabel, 
                    dataIndex: columnMapping.Name,
                    ...getColumnSearchProps(columnMapping.Name),
                    // onFilter: (value, record) => record.name.indexOf(value) === 0,
                    sorter: (a, b) => {
                        // Dynamically sort based on the column's dataIndex
                        const valueA = a[columnMapping.Name] ?? '';
                        const valueB = b[columnMapping.Name] ?? '';
                
                        // If the data is numeric, compare numerically, otherwise compare alphabetically
                        return typeof valueA === 'number' && typeof valueB === 'number'
                          ? valueA - valueB
                          : valueA.toString().localeCompare(valueB.toString());
                      },
                    sortDirections: ['ascend', 'descend'], // Enable both A-Z and Z-A
                })),
                {
                    title: <Button icon={<PlusOutlined />} onClick={openDatasetDataEditorToAdd} />,
                    dataIndex: 'action',
                    render: (_, record) => {
                        return (<Space size="middle">
                            <Button icon={<EditFilled />} onClick={()=> openDatasetDataEditorToEdit(record)} />
                        </Space>);
                    },
                }]);
        }
    }, [datasetColumnMappings]);

    const fetchData = () => {
        if(dataset && dataset.Id && columns && columns.length > 0){
            setLoading(true);
            execute('GET', `${protectedResources.api.baseEndPoint}/datasetData/${dataset.Id}?${qs.stringify(getTableParams(tableParams))}`)            
            .then((response) => {
                if (response.success) {
                    console.log("fetchData", response.data);
                    
                    // Normalize null values
                    const normalizedData = response.data.map((item) => ({
                        ...item,
                        Manager: item.Manager || "", // Replace null with an empty string
                        Program_Specialist: item.Program_Specialist || "", // Replace null with an empty string
                    }));
                    console.log(normalizedData)
                    setData(normalizedData);
                    
                        setTableParams({
                          ...tableParams,
                          pagination: {
                            ...tableParams.pagination,
                            total: response.totalRecords, // Update total records for pagination
                          },
                        });
                      
                }else{
                    message.error(response.message);
                }
                setLoading(false);
            });
        }
    };

    const fetchAllData = () => {
        if(dataset && dataset.Id && columns && columns.length > 0){
            setLoading(true);
            execute('GET', `${protectedResources.api.baseEndPoint}/datasetData/${dataset.Id}?${qs.stringify(getTableParams({
                pagination: {
                    current: 1,
                    pageSize: -1,
                  }
                }))}`)            
            .then((response) => {
                if (response.success) {
                    console.log("fetchData", response.data);
                    // Normalize null values
                    const normalizedData = response.data.map((item) => ({
                        ...item,
                        Manager: item.Manager || "", // Replace null with an empty string
                        Program_Specialist: item.Program_Specialist || "", // Replace null with an empty string
                    }));
                    console.log(normalizedData)
                    setData(normalizedData);                   
                    setData(response.data);
                    
                        // setTableParams({
                        //   ...tableParams,
                        //   pagination: {
                        //     pagination: -1,
                        //     total: response.totalRecords, // Update total records for pagination
                        //   },
                        // });
                      
                }else{
                    message.error(response.message);
                }
                setLoading(false);
            });
        }
    };

    useEffect(()=>{
        if(dataset?.Id && columns && columns.length > 0){
            fetchData();
        }
    }, [
        dataset?.Id, columns,
        tableParams?.pagination?.current,
        tableParams?.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        tableParams?.filters,
        // JSON.stringify(tableParams?.filters),
    ]);

    useEffect(() => {
        console.log("data", data);
    }, [data]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
          sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
          sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });
    
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
          //setData([]);
        }
    };

    return (
        <>
        <div>
            <h1 className="table-heading">{dataset?.Name}</h1>
                <Table 
                    bordered
                    dataSource={data}
                    columns={columns} 
                    pagination={{ ...tableParams.pagination }} 
                    rowKey={(record) =>primaryKeyColumnName ? record[primaryKeyColumnName] : ''}
                    loading={loading}
                    showSorterTooltip={{
                        target: 'sorter-icon',
                      }}
                    onChange={handleTableChange} />
        </div>
        {isDataEditorOpen ?
            <DataEditor 
                isDataEditorOpen={isDataEditorOpen} 
                data={editableDataItem} 
                dataset={dataset} 
                datasetColumnMappings={datasetColumnMappings}
                onDataEditorFinished={handleDataEditFinished}
                onDataEditorCancelled={handleDataEditCancelled} />
                : null}
        </>
    );
};