import React from 'react';
import {
    FaArrowLeft,
    
    FaCalendar,
    
    FaCalendarDays,
    
    FaChevronDown,
} from 'react-icons/fa6';

import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { FaArrowRightLong } from 'react-icons/fa6';
import { MdLocationOn } from "react-icons/md";
import { DatePicker } from "antd";


const NewCitationForm = () => {
    const navigate = useNavigate();
    return (
        <div className="mt-8">
            <div className="text-[#002C57] text-[30px]">New Citation</div>

            <div
                className="text-[#F16622] flex text-2xl gap-2 mt-10 cusror-pointer"
                onClick={() => {
                    navigate(-1);
                }}
            >
                <FaArrowLeft className="mt-1" />

                <div>Back</div>
            </div>

            <div className="mt-10 bg-[#F2F2F2] p-2 flex gap-4">
                <div className='bg-white p-2 text-[#F16622] text-lg font-semibold'>
                    Citation
                </div>

                <div className='bg-white p-2  text-lg font-semibold'>
                    Provider Plan Of Action
                </div>

                <div className='bg-white p-2 text-lg font-semibold'>
                    Additional Corrective Actions
                </div>

                <div className='bg-white p-2  text-lg font-semibold'>
                    Review
                </div>
            </div>

            <div className="mt-10">
                <div className="text-[#002C57] text-lg font-semibold mb-4">
                    Primary Information
                </div>
                <div className="flex gap-16">
                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4">
                            Inspection Facility ID
                        </div>
                        <select className="appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[250px]">
                            <option value="" disabled selected>
                                Select
                            </option>
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                            <option value="option3">Option 3</option>
                        </select>
                        <FaChevronDown className="h-5 w-5 text-[#F16622] absolute right-3 top-14" />
                    </div>

                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4">
                            Audit From Date
                        </div>
                        <DatePicker
                            className="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 p-4 w-[250px]"
                            calendarClassName="bg-white border border-gray-300  shadow-lg"
                        />
                        <FaCalendarDays className="text-[#F16622] right-3 top-14 text-lg absolute" />
                    </div>

                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4">
                            Audit To Date
                        </div>
                        <DatePicker
                            className="border border-gray-300   focus:outline-none focus:ring-2 focus:ring-blue-500 p-4  w-[250px] "
                            calendarClassName="bg-white border border-gray-300  shadow-lg"
                        />
                        <FaCalendarDays className="text-[#F16622] right-3 top-14 text-lg absolute" />
                    </div>

                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4">
                            Audit Agency
                        </div>
                        <select className="appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[250px]">
                            <option value="" disabled selected>
                                Select
                            </option>
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                            <option value="option3">Option 3</option>
                        </select>
                        <FaChevronDown className="h-5 w-5 text-[#F16622] absolute right-3 top-14" />
                    </div>

                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4">
                            Audit Type
                        </div>
                        <select className="appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[250px]">
                            <option value="" disabled selected>
                                Select
                            </option>
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                            <option value="option3">Option 3</option>
                        </select>
                        <FaChevronDown className="h-5 w-5 text-[#F16622] absolute right-3 top-14" />
                    </div>
                </div>

                <div className="flex gap-4 w-full mt-6">
                    <div className="w-1/2">
                        <div className="text-[#767676] font-medium text-base mb-4 mb-4">
                            Reviewer
                        </div>
                        <input className="w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]" />
                    </div>
                    <div className="w-1/2">
                        <div className="text-[#767676] font-medium text-base mb-4 mb-4">
                            Additional Reviewer
                        </div>
                        <input className="w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]" />
                    </div>
                </div>

                <div className="border-t border-t-gray-300 mt-4"></div>

                <div className="mt-10 ">
                    <div className="text-[#002C57] text-lg font-semibold">
                        Secondary Information
                    </div>

                    <div className="flex gap-14">
                        <div className="relative  w-[400px]">
                            <div className="text-[#767676] font-medium text-base mb-4 mt-4">
                                Citation Type
                            </div>

                            <select className="appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[400px]">
                                <option value="option1">
                                    Individual Record
                                </option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </select>
                            <FaChevronDown className="h-5 w-5 text-[#F16622] absolute right-3 top-20" />
                        </div>

                        <div className="relative  w-1/2">
                            <div className="text-[#767676] font-medium text-base mb-4 mt-4">
                                Customer Name
                            </div>

                            <input
                                className="w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]"
                                placeholder="Type In"
                            />
                        </div>

                        <div className="relative  w-1/2">
                            <div className="text-[#767676] font-medium text-base mb-4 mt-4">
                                Location
                            </div>

                            <input
                                className="w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]"
                                placeholder="Type In"
                            />
                            <MdLocationOn className="h-5 w-5 text-[#F16622] absolute right-3 top-20" />
                        </div>
                    </div>

                    <div className="text-[#767676] font-medium text-base mt-4 mb-4">
                        Input the individual who's record is being inspected ,
                        if applicable
                    </div>

                    <div className="border-t border-t-gray-300 mt-4"></div>
                </div>

                <div className="text-[#002C57] text-lg font-semibold mt-6">
                    Additional Information
                </div>

                <div className="flex gap-16">
                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4">
                            Regular Number- Desc
                        </div>
                        <select className="appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[250px]">
                            <option value="" disabled selected>
                                Select
                            </option>
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                            <option value="option3">Option 3</option>
                        </select>
                        <FaChevronDown className="h-5 w-5 text-[#F16622] absolute right-3 top-14" />
                    </div>

                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4">
                            Corrective Action Type
                        </div>
                        <select className="appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[250px]">
                            <option value="" disabled selected>
                                Select
                            </option>
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                            <option value="option3">Option 3</option>
                        </select>
                        <FaChevronDown className="h-5 w-5 text-[#F16622] absolute right-3 top-14" />
                    </div>

                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4">
                            Corrective Action Category
                        </div>
                        <select className="appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[250px]">
                            <option value="" disabled selected>
                                Select
                            </option>
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                            <option value="option3">Option 3</option>
                        </select>
                        <FaChevronDown className="h-5 w-5 text-[#F16622] absolute right-3 top-14" />
                    </div>

                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4">
                            Corrective Action From Date
                        </div>
                        <DatePicker
                            className="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 p-4 w-[250px]"
                            calendarClassName="bg-white border border-gray-300  shadow-lg"
                        />
                        <FaCalendarDays className="text-[#F16622] right-3 top-14 text-lg absolute" />
                    </div>

                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4">
                            Corrective Action To Date
                        </div>
                        <DatePicker
                            className="border border-gray-300   focus:outline-none focus:ring-2 focus:ring-blue-500 p-4  w-[250px] "
                            calendarClassName="bg-white border border-gray-300  shadow-lg"
                        />
                        <FaCalendarDays className="text-[#F16622] right-3 top-14 text-lg absolute" />
                    </div>
                </div>

                <div className="flex gap-14 mt-4">
                    <div className="w-1/2">
                        <div className="text-[#767676] font-medium text-base mb-4 mt-4">
                            Responsible Party
                        </div>
                        <input
                            className="w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]"
                            Placeholder="Enter name or email address"
                        />
                    </div>

                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4 mt-3">
                            Corrective Action Completion From Date
                        </div>
                        <DatePicker
                            className="border border-gray-300   focus:outline-none focus:ring-2 focus:ring-blue-500 p-4  w-[350px] "
                            calendarClassName="bg-white border border-gray-300  shadow-lg"
                        />
                        <FaCalendarDays className="text-[#F16622] right-3 top-16 text-lg absolute" />
                    </div>

                    <div className="relative">
                        <div className="text-[#767676] font-medium text-base mb-4 mt-3">
                            Corrective Action Completion To Date
                        </div>
                        <DatePicker
                            className="border border-gray-300   focus:outline-none focus:ring-2 focus:ring-blue-500 p-4  w-[350px] "
                            calendarClassName="bg-white border border-gray-300  shadow-lg"
                        />
                        <FaCalendarDays className="text-[#F16622] right-3 top-16 text-lg absolute" />
                    </div>
                </div>

                <div className="flex gap-14 mt-4">
                    <div className="w-1/2">
                        <div className="text-[#767676] font-medium text-base mb-4 mt-4">
                            Describe Citation/Violation of Regularization
                        </div>
                        <textarea
                            className="w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]"
                            Placeholder="Type In"
                        />
                    </div>

                    <div className="w-1/2">
                        <div className="text-[#767676] font-medium text-base mb-4 mt-3">
                            Corrective Action Required By Inspectior
                        </div>
                        <textarea
                            className="w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]"
                            Placeholder="Type In"
                        />
                    </div>
                </div>

                <div className="flex gap-4 w-full mt-6">
                    <div className="w-full">
                        <div className="text-[#767676] font-medium text-base mb-4 mt-4">
                            Comments/Follow-up Comments
                        </div>
                        <input
                            className="w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]"
                            placeholder="Type In"
                        />
                    </div>
                </div>

                <div className="flex mt-6 gap-4 mb-4">
                    <div
                        // onClick={navigate('/newinspectionform')}
                        className="py-3 px-8  border border-orange-600 text-orange-600 rounded-3xl cursor-pointer font-semibold"
                    >
                        Cancel
                    </div>

                    <div
                        // onClick={navigate('/newinspectionform')}
                        className="py-3 px-10  bg-orange-600 text-white rounded-3xl hover:bg-orange-700 cursor-pointer font-semibold"
                    >
                        Add
                    </div>
                    <div
                        // onClick={navigate('/newinspectionform')}
                        className="py-3 px-8  border border-orange-600 text-orange-600 rounded-3xl cursor-pointer font-semibold flex gap-2"
                    >
                        Provider Plan of Action  <FaArrowRightLong className="mt-1" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewCitationForm;
