import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import styled from "styled-components";
import Footer from "./Footer";
import Header from './Header';
import Sidebar from './Sidebar';
import { useEffect } from "react";
import { loginRequest } from '../config/msalConfig';
import { InteractionStatus } from '@azure/msal-browser';
import Feedback from "../views/Feedback";
import { App } from 'antd';

export const PageLayout = ( props ) => {
    const { instance, inProgress } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }
    
    useEffect(() => {
      if(!activeAccount && inProgress === InteractionStatus.None){
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));            
      }
    }, [inProgress, instance, activeAccount]);

    return (
         <AuthenticatedTemplate>
            <AppContainer>
              <App>
                <Header  />
                <Feedback />
                <RowContainer>
                    <Sidebar/>
                    {props.children}
                </RowContainer>
                <Footer />
              </App>
            </AppContainer>            
         </AuthenticatedTemplate>        
    );
}
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  gap: 0px; 
`;
const RowContainer = styled.div`
  max-height: calc(100vh - 150px);
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px; // Adjust the gap between components as needed
  height: 100%; // Set the height to 100% of its parent
  flex-grow: 1; // Allow it to expand to fill available space
  @media (max-width: 991px) {
    flex-direction: column; // Stack them on smaller screens
  }
`;
export default PageLayout;