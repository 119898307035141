import './faq.css';

export const Faq = () => {
    return (
        <div className="faq-wrapper">
            <div className="faq-container">
                <h1 className="faq-header">FAQ</h1>
                <div className="faq-item">
                    <h2>1. What is the purpose of this SaaS application?</h2>
                    <p>Our SaaS application is designed to provide Human Health Services providers with actionable insights through embedded PowerBI reports, helping them optimize operations, improve service delivery, and make data-driven decisions.</p>
                </div>
                <div className="faq-item">
                    <h2>2. Who is this SaaS platform for?</h2>
                    <p>The platform is tailored for organizations in the Human Health Services sector, including providers in Behavioral Health, Intellectual and Developmental Disabilities (IDD), Children & Youth Services, and Addiction Services.</p>
                </div>
                <div className="faq-item">
                    <h2>3. What is the MVP1.0 version, and how is it different from future releases?</h2>
                    <p>MVP1.0 is our initial release that includes the core functionality, such as PowerBI embedded reports, real-time data analytics, and a user-friendly interface. Future releases will introduce more advanced features based on user feedback from this pilot phase.</p>
                </div>
                <div className="faq-item">
                    <h2>4. What types of reports are available in the MVP1.0 release?</h2>
                    <p>The MVP1.0 release includes key data reports on service utilization, client demographics, operational efficiency, and outcomes, all embedded in PowerBI for easy access and interaction.</p>
                </div>
                <div className="faq-item">
                    <h2>5. How do I navigate through the dashboard and reports?</h2>
                    <p>You can easily navigate through the dashboard by using filters to focus on specific data areas. Reports are interactive, and you can drill down into details by clicking on specific data points.</p>
                </div>
                <div className="faq-item">
                    <h2>6. How often is the data in the reports updated?</h2>
                    <p>The data in the reports is updated at regular intervals, providing near real-time insights. If necessary, you can trigger a manual refresh to get the latest data.</p>
                </div>
                <div className="faq-item">
                    <h2>7. Can I customize the reports for my organization’s specific needs?</h2>
                    <p>Not in the current release. In the future releases, users can customize the reports by using the PowerBI edit mode to create views that focus on the metrics and data points that matter most to their organization.</p>
                </div>
                <div className="faq-item">
                    <h2>8. How do I export or share reports with my team or stakeholders?</h2>
                    <p>Reports can be easily exported to common formats such as Excel or PDF, enabling you to share them with your team or external stakeholders.</p>
                </div>
                <div className="faq-item">
                    <h2>9. Is the platform secure and compliant with data privacy regulations?</h2>
                    <p>Absolutely. The platform is fully compliant with data privacy regulations, including HIPAA, ensuring that all data is securely encrypted and protected throughout the process.</p>
                </div>
                <div className="faq-item">
                    <h2>10. How can I provide feedback on the platform during the pilot phase?</h2>
                    <p>We encourage users to share their feedback with us directly through the platform’s ‘Feedback’ feature or via email. Your insights will help us improve the platform and introduce new features in future releases. </p>
                </div>
                <div className="faq-item">
                    <h2>11. Will the platform be accessible on mobile devices?</h2>
                    <p>While the MVP1.0 release is desktop-focused, we are working on making the platform mobile-responsive in future releases, allowing you to access reports on smartphones and tablets.</p>
                </div>
                <div className="faq-item">
                    <h2>12. What should I do if I encounter issues or have questions while using the platform?</h2>
                    <p>If you encounter any issues or need assistance, our support team is available to help. You can reach out through the in-app support feature or contact us via email.</p>
                </div>
                <div className="faq-item">
                    <h2>13. Can I set up custom alerts for important metrics?</h2>
                    <p>This feature is currently in development. In future releases, users will be able to set custom alerts based on specific data thresholds, allowing them to stay informed on critical metrics.</p>
                </div>
                <div className="faq-item">
                    <h2>14. What future enhancements can I expect in the platform?</h2>
                    <p>In addition to mobile accessibility, upcoming features include expanded filtering options, ability to upload files, manage custom data in an easy to use ‘Data Management’ module and custom alert notifications for key performance indicators.</p>
                </div>
                <div className="faq-item">
                    <h2>15. How will my feedback influence future updates?</h2>
                    <p>Your feedback during the pilot phase is crucial in shaping the final version of the platform. We will use your insights to enhance the product, introduce new features, and improve the overall user experience.</p>
                </div>

            </div>            
        </div>

    );
}
