import { App, Descriptions, Select } from "antd";
import { data } from "autoprefixer";
import { useUser } from "components/UserContext";
import { protectedResources } from "config/msalConfig";
import execute from "hooks/execute";
import { useEffect, useState } from "react";


export const FilterBar = ({onFilterChange}) => {
    const { userDetails } = useUser();
    const { message } = App.useApp();    
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState(5);
    const [datasets, setDatasets] = useState([]);
    const [datasetColumns, setDatasetColumns] = useState([]);
    const [selectedModuleDataset, setSelectedModuleDataset] = useState();
    const [allDatasets, setAllDatasets] = useState({});
    const [allDataSetColumns, setAllDataSetColumns] = useState({});

    useEffect(() => {
        // Fetch modules
        if(userDetails && userDetails.Modules) {
            setModules(userDetails.Modules);  
            console.log(userDetails.Modules);   
            loadModuleDatasets(selectedModule);            
        }
    }, [userDetails]);

    useEffect(() => {
        if(selectedModule && selectedModuleDataset && datasetColumns){
            onFilterChange({
                selectedModule: modules.find(m=>m.Id === selectedModule), 
                selectedModuleDataset: datasets.find(m=>m.Id === selectedModuleDataset) , 
                datasetColumns: datasetColumns});
        }
    }, [selectedModule, selectedModuleDataset, datasetColumns]);


    const loadModuleDatasets = async (moduleId) => {
        if(allDatasets[moduleId.toString()]) {
            setDatasets(allDatasets[moduleId.toString()]);
            if(allDatasets[moduleId.toString()].length > 0){
                setSelectedModuleDataset(allDatasets[moduleId.toString()][0].Id);                
                loadModuleDatasetColumns(allDatasets[moduleId.toString()][0].Id);
            }else{
                setSelectedModuleDataset(null);
            }
            return;
        }
        const response = await execute('GET', `${protectedResources.api.baseEndPoint}/modules/${moduleId}/datasets`);
        console.log(response);
        if(response && response.success && response.data) {
            console.log(response.data);
            setAllDatasets({ ...allDatasets, [`${moduleId}`]: response.data});
            setDatasets(response.data);
            if(response.data.length > 0){
                setSelectedModuleDataset(response.data[0].Id);
                loadModuleDatasetColumns(response.data[0].Id);
            }else{
                setSelectedModuleDataset(null);
            }
        }
    }

    const loadModuleDatasetColumns = async (datasetId) => {
        if(allDataSetColumns[datasetId.toString()]) {
            setDatasetColumns(allDataSetColumns[datasetId.toString()]); 
        }else{
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/datasets/${datasetId}/columns`);
            console.log(response);
            if(response && response.success && response.data) {
                console.log(response.data);
                setAllDataSetColumns({ ...allDataSetColumns, [`${datasetId}`]: response.data});
                setDatasetColumns(response.data);
                console.log("loadModuleDatasetColumns: ", allDatasets, allDataSetColumns);
            }
        }            
    }

    const handleModuleChange = (value) => {
        setSelectedModule(value);
        loadModuleDatasets(value);
        console.log("handleModuleChange: ", value);
    }

    const handleModuleDatasetChange = (value) => {
        setSelectedModuleDataset(value);
        loadModuleDatasetColumns(value);
        console.log("handleModuleDatasetChange: ", value);
    }


    return (
        <Descriptions 
            title="" 
            bordered            
            column={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
            items={[
                {
                    label: 'Module',
                    children: (
                        <Select 
                            style={{minWidth: "300px"}} 
                            value={selectedModule}
                            onChange={handleModuleChange}
                            options={ modules.map((m)=>({
                                label: m.ModuleName,
                                value: m.Id
                            })) } />)
                },
                {
                    label: 'Dataset',
                    children: (
                        <Select 
                        style={{minWidth: "300px"}}
                        value={selectedModuleDataset}
                        onChange={handleModuleDatasetChange}
                        options={ datasets.map((m)=>({
                            label: m.Name,
                            value: m.Id
                        })) } />)
                }
            ]}
        />
    );
};