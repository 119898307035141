

const UserDataService = {};

UserDataService.setUserData = function (data) {
    //console.log('User data set:', data);
    localStorage.setItem('userData', JSON.stringify(data));
};

UserDataService.getUserData = function () {
    //console.log('Getting user data');
    var data = localStorage.getItem('userData');
    //console.log('User data retrieved:', data);
    return JSON.parse(data);
}

UserDataService.clearUserData = function () {
    localStorage.removeItem('userData');
}


export default UserDataService;

