import React, { useState,useEffect,useMemo } from 'react';

import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import execute from '../hooks/execute';
import { protectedResources } from '../config/msalConfig';
import { DatePicker } from "antd";

function ProviderPlan() {  
  return (
    <Columnn>
      <NewCitation />
      <NavigationBar/>
      <ProviderPlanOfCorrectiveAction />
    </Columnn>
  );
}

const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  width: 110%;
  gap: 20px; // Adjust the vertical gap between MyComponent1 and MyComponent3
`;




function NewCitation() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { userDetails } = useUser();
  
    const toggleModal = () => {
      setModalIsOpen(!modalIsOpen);
    };
  
    return (
      <div>
        <Header1>
          <Title>New Citation</Title>
          {/* <NewOrganizationButton onClick={toggleModal}>New User</NewOrganizationButton> */}
  
        </Header1>
              {/* <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} userEmail={userDetails.email}  /> */}
  
      </div>
    );
  }
  
  
  const Header1 = styled.header`
  display: flex;
  padding: 10px; // Adjusted padding for the header
  margin-right: -100px; // Ensure this is actually needed, otherwise remove
  margin-top: 20px;
  margin-left:-170px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  `;
  
  
  const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-primary-color-blue-900, #002c57);
  `;
  
  const NewCitation1 = styled.button`
  padding: 10px 18px;
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-black-white-white, #fff);
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 790px;
  `;

// Component for each navigation item
const NavigationItem = ({ text, isActive, onClick }) => (
    <NavItem isActive={isActive} onClick={onClick}>
      {text}
    </NavItem>
  );
  
  // Navigation bar component with dynamic active state management
  function NavigationBar() {
    const [activeIndex, setActiveIndex] = useState(0); // State to track the active item
    const [displayText, setDisplayText] = useState(""); // State to track text to display when item is clicked
    const navigate = useNavigate();

    const navItems = [
      { text: "Citation", route:"/newcitation"},
      { text: "Provider Plan of Action",route: "/provider-plan"  },
      { text: "Additional Corrective Actions",route: "/additional-action"},
      { text: "Review",route: "/review" },    ];
      
    const handleItemClick = (index, text, route) => {
        setActiveIndex(index);
        setDisplayText(`You clicked on: ${text}`);
        navigate(route);
      };
  
    return (
        <SearchContainer>
        <nav className="navigation-bar">
          {navItems.map((item, index) => (
            <NavigationItem
              key={index}
              text={item.text}
              isActive={index === activeIndex}  // Determine if the item is active based on index
            //   onClick={() => handleItemClick(index, item.text)}
            onClick={() => handleItemClick(index, item.text, item.route)}

            />
          ))}
        </nav>
        <div>{displayText}</div> {/* Display text area */}
      </ SearchContainer>
    );
  }
  
  const SearchContainer = styled.section`
  border-radius: 3px;
  background-color: var(--bicollab-black-white-black-100, #f2f2f2);
  display: flex;
  gap: 20px;
  margin-top:-20px;
  margin-left: -160px;
  margin-right: -120px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  padding: 6px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

const NavItem = styled.button`
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  border-radius: 4px; // Rounded corners for a smooth box appearance
  background-color: var(--bicollab-black-white-white, #fff); // Light background
  color: ${props => props.isActive ? 'var(--bicollab-secondary-color-orange-700, #f16622)' : 'var(--bicollab-black-white-black-500, #515151)'};
  padding: 10px 15px; // Slightly increased padding for better visual spacing
  border: 2px solid var(--bicollab-black-white-grey-300, #ddd); // Visible border to emphasize the box
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1); // Subtle shadow for a 3D effect
  cursor: pointer;
  transition: all 0.3s ease; // Smooth transition for hover effects
  margin-right: 10px;
  &:hover {
    background-color: var(--bicollab-grey-200, #f7f7f7); // Change background on hover
    box-shadow: 0px 4px 8px rgba(0,0,0,0.15); // Increase shadow on hover for a "lifted" effect
  }

  @media (max-width: 991px) {
    white-space: normal; // Ensures text wraps properly in smaller screens
  }
`;

  const StyledNavigationBar = styled.nav`
    border-radius: 3px;
    background-color: var(--bicollab-black-white-black-100, #f2f2f2);
    display: flex;
    gap: 12px;
    padding: 6px 14px;
  
    @media (max-width: 991px) {
      flex-wrap: wrap;
      padding-right: 20px;
    }
  `;


const ProviderPlanOfCorrectiveAction = () => {
    const [selectedValues, setSelectedValues] = useState({
      providerPOCDueDate: null,
      providerPOCStatus: 'Licensing',
      providerPOCCompletionDate: null,
      status: 'In Process',
    });
  
    const handleDateChange = (field, date) => {
      setSelectedValues((prev) => ({ ...prev, [field]: date }));
    };
  
    const handleDropdownChange = (field, value) => {
      setSelectedValues((prev) => ({ ...prev, [field]: value }));
    };
  
    const handleAddAction = () => {
      console.log('Add action clicked');
    };
  
    const handleCancel = () => {
      console.log('Cancel clicked');
    };
  
    const handleSave = async () => {
      console.log('Save clicked');
      console.log(selectedValues);
    };
  
    const handleAdditionalActions = () => {
      console.log('Additional Corrective Actions clicked');
    };
  
    const dropdownOptions = [
      {
        label: 'Provider POC Due Date',
        field: 'providerPOCDueDate',
        isDate: true,
      },
      {
        label: 'Provider POC Status',
        field: 'providerPOCStatus',
        options: ['Licensing', 'Option 1', 'Option 2'],
      },
      {
        label: 'Provider POC Completion Date',
        field: 'providerPOCCompletionDate',
        isDate: true,
      },
      {
        label: 'Status',
        field: 'status',
        options: ['In Process', 'Option 1', 'Option 2'],
      },
    ];
  
    return (
      <Container>
        <Title1>Provider's Plan of Corrective Action</Title1>
        <DropdownSection>
          {dropdownOptions.map((option, index) =>
            option.isDate ? (
              <DatePickerField
                key={index}
                label={option.label}
                selected={selectedValues[option.field]}
                onChange={(date) => handleDateChange(option.field, date)}
              />
            ) : (
              <DropdownField
                key={index}
                label={option.label}
                value={selectedValues[option.field]}
                options={option.options}
                onChange={(value) => handleDropdownChange(option.field, value)}
                iconSrc={`http://b.io/ext_${index + 1}-`}
              />
            )
          )}
        </DropdownSection>
        <AddActionButton onClick={handleAddAction}>Add+</AddActionButton>
        <ActionFooter>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
          <SaveButton type="button" onClick={handleSave}>Save</SaveButton>
          <AdditionalActionsButton onClick={handleAdditionalActions}>
            Additional Corrective Actions
            <ActionIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/11a66aa01c9ed3d3ab706b1c18a13560e6d5bb36a3cd8d5d4aeb69e78f7aa86b?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
          </AdditionalActionsButton>
        </ActionFooter>
      </Container>
    );
  };
  
  const DatePickerField = ({ label, selected, onChange }) => (
    <DatePickerContainer>
      <DropdownLabel>{label}</DropdownLabel>
      <DatePickerWrapper>
        <DatePicker
          selected={selected}
          onChange={onChange}
          dateFormat="yyyy/MM/dd"
          placeholderText="Select Date"
        />
      </DatePickerWrapper>
    </DatePickerContainer>
  );
  
  const DropdownField = ({ label, value, options, onChange, iconSrc }) => (
    <DropdownContainer>
      <DropdownLabel>{label}</DropdownLabel>
      <DropdownWrapper>
        <select value={value} onChange={(e) => onChange(e.target.value)}>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <DropdownIcon src={iconSrc} alt="" />
      </DropdownWrapper>
    </DropdownContainer>
  );
  
  
const DatePickerContainer = styled.div`
display: flex;
flex-direction: column;
`;

const DatePickerWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
margin-top: 13px;
padding: 10px 21px;
border: 1px solid rgba(187, 187, 188, 1);
border-radius: 2px;
background-color: var(--bicollab-black-white-white, #fff);
font-size: 16px;
font-weight: 400;
font-family: Ubuntu, sans-serif;
`;

  const Container = styled.main`
    // border-radius: 2px;
    // padding: 17px 11px;
    // line-height: 1.14;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    padding: 17px 11px;
    margin: 0 auto; // Center the form container
    // box-sizing: border-box;
    width:120%;
    margin-left: -150px;
   
  `;
  
  const Title1 = styled.h1`
    color: var(--bicollab-primary-color-blue-900, #002c57);
    font: 500 14px Ubuntu, sans-serif;
    letter-spacing: 0.4px;
  `;
  
  const DropdownSection = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 24px;
  `;
  
  const DropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;
  
  const DropdownLabel = styled.label`
    color: #767676;
    text-align: center;
    font: 500 14px Ubuntu, sans-serif;
  `;
  
  const DropdownWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 13px;
    padding: 10px 21px;
    border: 1px solid rgba(187, 187, 188, 1);
    border-radius: 2px;
    background-color: var(--bicollab-black-white-white, #fff);
    font-size: 16px;
    font-weight: 400;
    font-family: Ubuntu, sans-serif;
  
    select {
      appearance: none;
      background: transparent;
      border: none;
      font-family: Ubuntu, sans-serif;
      font-size: 16px;
      color: var(--bicollab-black-white-black-700, #1c1c1c);
      cursor: pointer;
    }
  `;
  
  const DropdownIcon = styled.img`
    width: 24px;
    height: 24px;
  `;
  
  const AddActionButton = styled.button`
    color: var(--bicollab-secondary-color-orange-700, #f16622);
    font: 500 14px Ubuntu, sans-serif;
    letter-spacing: 0.4px;
    margin-top: 20px;
    background: none;
    border: none;
    cursor: pointer;
  `;
  
  const ActionFooter = styled.footer`
    display: flex;
    gap: 13px;
    margin-top: 58px;
    font-size: 20px;
    font-weight: 500;
  `;
  
  const Button = styled.button`
    padding: 10px 18px;
    border-radius: 50px;
    font-family: Ubuntu, sans-serif;
    cursor: pointer;
  `;
  
  const CancelButton = styled(Button)`
    color: var(--bicollab-secondary-color-orange-700, #f16622);
    background-color: var(--bicollab-black-white-white, #fff);
    border: 1px solid rgba(241, 102, 34, 1);
  `;
  
  const SaveButton = styled(Button)`
    color: var(--bicollab-black-white-white, #fff);
    background-color: var(--bicollab-secondary-color-orange-700, #f16622);
    border: none;
  `;
  
  const AdditionalActionsButton = styled(Button)`
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--bicollab-secondary-color-orange-700, #f16622);
    background-color: var(--bicollab-black-white-white, #fff);
    border: 1px solid rgba(241, 102, 34, 1);
  `;
  
  const ActionIcon = styled.img`
    width: 20px;
    height: 20px;
  `;
export default ProviderPlan;