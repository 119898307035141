// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.faq-wrapper {
    overflow-y: auto;
    width: 100%;
}
.faq-container {
    padding: 50px;
    /* max-width: 800px;
    margin: auto; */
    font-family: Arial, sans-serif;
}

.faq-header {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 40px;
    font-weight: 600;
}

.faq-item {
    margin-bottom: 20px;
}

.faq-item h2 {
    font-size: 1.2em;
    color: #f16622;
}

.faq-item p {
    font-size: 1em;
    color: #555;
    line-height: 1.5;
}`, "",{"version":3,"sources":["webpack://./src/views/Faq/faq.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,aAAa;IACb;mBACe;IACf,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB","sourcesContent":["\n.faq-wrapper {\n    overflow-y: auto;\n    width: 100%;\n}\n.faq-container {\n    padding: 50px;\n    /* max-width: 800px;\n    margin: auto; */\n    font-family: Arial, sans-serif;\n}\n\n.faq-header {\n    text-align: center;\n    margin-bottom: 20px;\n    color: #333;\n    font-size: 40px;\n    font-weight: 600;\n}\n\n.faq-item {\n    margin-bottom: 20px;\n}\n\n.faq-item h2 {\n    font-size: 1.2em;\n    color: #f16622;\n}\n\n.faq-item p {\n    font-size: 1em;\n    color: #555;\n    line-height: 1.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
