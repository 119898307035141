import React, { useState,useEffect,useMemo } from 'react';

import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import execute from '../hooks/execute';
import { protectedResources } from '../config/msalConfig';

function Inspection() {

    
    
  

  return (
  <Columnn>
    <NewCitation />
    <InspectionForm />
  </Columnn>
  );
}

const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px; // Adjust the vertical gap between MyComponent1 and MyComponent3
`;



function NewCitation() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { userDetails } = useUser();
  
    const toggleModal = () => {
      setModalIsOpen(!modalIsOpen);
    };
  
    return (
      <div>
        <Header1>
          <Title>New Inspection</Title>
          {/* <NewOrganizationButton onClick={toggleModal}>New User</NewOrganizationButton> */}
  
        </Header1>
              {/* <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} userEmail={userDetails.email}  /> */}
  
      </div>
    );
  }
  
  
  const Header1 = styled.header`
  display: flex;
  padding: 10px; // Adjusted padding for the header
  margin-right: -100px; // Ensure this is actually needed, otherwise remove
  margin-top: 20px;
  margin-left:-170px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  `;
  
  
  const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-primary-color-blue-900, #002c57);
  `;
  
  const NewCitation1 = styled.button`
  padding: 10px 18px;
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-black-white-white, #fff);
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 790px;
  `;

  
  const SelectField = ({ label, options, defaultValue }) => (
    <SelectWrapper>
      <SelectLabel>{label}</SelectLabel>
      <SelectContainer>
        <Select defaultValue={defaultValue}>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Select>
        <SelectIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
      </SelectContainer>
    </SelectWrapper>
  );
  
  const InputField = ({ label, placeholder }) => (
    <InputWrapper>
      <InputLabel>{label}</InputLabel>
      <Input placeholder={placeholder} />
    </InputWrapper>
  );
  
  function InspectionForm() {
    return (
      <FormContainer>
        <SectionTitle>Primary Information</SectionTitle>
        <PrimaryInfoSection>
          <SelectField
            label="Inspection Date"
            options={["Select"]}
            defaultValue="Select"
          />
          <SelectField
            label="Inspection Facility"
            options={["Select"]}
            defaultValue="Select"
          />
          <SelectField
            label="Program Type"
            options={["Select"]}
            defaultValue="Select"
          />
          <SelectField
            label="Audit Agency"
            options={["Select"]}
            defaultValue="Select"
          />
          <SelectField
            label="Audit Type"
            options={["Licensing"]}
            defaultValue="Licensing"
          />
        </PrimaryInfoSection>
       
        <Divider />
        <SectionTitle>Secondary Information</SectionTitle>
        <CitationSection>
          <CitationLabel>Add Citation</CitationLabel>
          <CitationSelect>
            <span>Select citations</span>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
          </CitationSelect>
        </CitationSection>
        <CustomerTag>
          <span>Customer :</span>
          <span>Giacomo Guilizzoni</span>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/993d2b8380103033a2a7d57702d997cd5669993a791f8a2c99fc7ed755ba87dd?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
        </CustomerTag>
        <InstructionText>
          Input the individual who's record is being inspected, if applicable
        </InstructionText>
        <Divider />
        <SectionTitle>Additional Information</SectionTitle>
        <CommentSection>
          <CommentLabel>Comments/Follow-up Comments</CommentLabel>
          <CommentInput placeholder="Type in" />
        </CommentSection>
        <ActionButtons>
          <CancelButton>Cancel</CancelButton>
          <AddButton>Add</AddButton>
        </ActionButtons>
      </FormContainer>
    );
  }
  
  const FormContainer = styled.form`
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    padding: 17px 11px;
    width: 120%;
    margin-left: -160px;
    margin-top: -20px;
  `;
  
  const SectionTitle = styled.h2`
    color: var(--bicollab-primary-color-blue-900, #002c57);
    letter-spacing: 0.4px;
    font: 500 14px/114% Ubuntu, sans-serif;
    @media (max-width: 991px) {
      max-width: 100%;
    }
    margin-top:-1px;
  `;
  
  const PrimaryInfoSection = styled.section`
    display: flex;
    margin-top: 10px;
    gap: 20px;
    justify-content: space-between;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  `;
  
  const SelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
  `;
  
  const SelectLabel = styled.label`
    color: #767676;
    text-align: center;
    font: 500 14px Ubuntu, sans-serif;
  `;
  
  const SelectContainer = styled.div`
    justify-content: center;
    border-radius: 2px;
    border: 1px solid rgba(187, 187, 188, 1);
    background-color: var(--bicollab-black-white-white, #fff);
    display: flex;
    margin-top: 13px;
    gap: 10px;
    padding: 10px 21px;
    @media (max-width: 991px) {
      padding: 10px;
    }
  `;
  
  const Select = styled.select`
    font: 400 16px Ubuntu, sans-serif;
    color: var(--bicollab-black-white-black-400, #767676);
    background: transparent;
    border: none;
    appearance: none;
    width: 100%;
  `;
  
  const SelectIcon = styled.img`
    aspect-ratio: 1;
    width: 24px;
  `;
  
  const InspectorSection = styled.section`
    display: flex;
    margin-top: 30px;
    gap: 20px;
    justify-content: space-between;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  `;
  
  const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const InputLabel = styled.label`
    color: #767676;
    text-align: center;
    font: 500 14px Ubuntu, sans-serif;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const Input = styled.input`
    border-radius: 2px;
    border: 1px solid rgba(187, 187, 188, 1);
    background-color: var(--bicollab-black-white-white, #fff);
    margin-top: 13px;
    color: var(--bicollab-black-white-black-300, #bbbbbc);
    padding: 13px 21px;
    font: 400 16px Ubuntu, sans-serif;
    @media (max-width: 991px) {
      max-width: 100%;
      padding: 13px;
    }
  `;
  
  const Divider = styled.hr`
    width: 100%;
    stroke-width: 1px;
    stroke: var(--bicollab-black-white-black-400, #767676);
    margin-top: 20px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const CitationSection = styled.section`
    margin-top: 24px;
  `;
  
  const CitationLabel = styled.label`
    color: #767676;
    text-align: center;
    font: 500 14px Ubuntu, sans-serif;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const CitationSelect = styled.div`
    justify-content: center;
    border-radius: 2px;
    border: 1px solid rgba(187, 187, 188, 1);
    background-color: var(--bicollab-black-white-white, #fff);
    display: flex;
    margin-top: 13px;
    gap: 10px;
    font-size: 16px;
    color: var(--bicollab-black-white-black-300, #bbbbbc);
    font-weight: 400;
    padding: 10px 21px;
    @media (max-width: 991px) {
      flex-wrap: wrap;
      padding: 10px;
    }
  `;
  
  const CustomerTag = styled.div`
    border-radius: 16px;
    border: 1px solid rgba(241, 102, 34, 1);
    background-color: var(--bicollab-black-white-white, #fff);
    align-self: start;
    display: flex;
    margin-top: 20px;
    gap: 3px;
    color: var(--bicollab-black-white-black-700, #1c1c1c);
    font-weight: 400;
    letter-spacing: 0.4px;
    padding: 4px 11px;
  `;
  
  const InstructionText = styled.p`
    color: var(--bicollab-black-white-black-500, #515151);
    margin-top: 20px;
    font: 400 16px Ubuntu, sans-serif;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const CommentSection = styled.section`
    margin-top: 24px;
  `;
  
  const CommentLabel = styled.label`
    color: #767676;
    text-align: center;
    font: 500 14px Ubuntu, sans-serif;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const CommentInput = styled.textarea`
    border-radius: 2px;
    border: 1px solid rgba(187, 187, 188, 1);
    background-color: var(--bicollab-black-white-white, #fff);
    margin-top: 13px;
    color: var(--bicollab-black-white-black-300, #bbbbbc);
    padding: 19px 21px;
    font: 400 16px Ubuntu, sans-serif;
    width: 100%;
    @media (max-width: 991px) {
      max-width: 100%;
      padding: 19px;
    }
  `;
  
  const ActionButtons = styled.div`
    align-self: start;
    display: flex;
    margin-top: 58px;
    gap: 13px;
    font-size: 20px;
    font-weight: 500;
    @media (max-width: 991px) {
      margin-top: 40px;
    }
  `;
  
  const Button = styled.button`
    font-family: Ubuntu, sans-serif;
    justify-content: center;
    border-radius: 50px;
    padding: 10px 18px;
    @media (max-width: 991px) {
      padding: 10px;
    }
  `;
  
  const CancelButton = styled(Button)`
    border: 1px solid rgba(241, 102, 34, 1);
    background-color: var(--bicollab-black-white-white, #fff);
    color: var(--bicollab-secondary-color-orange-700, #f16622);
  `;
  
  const AddButton = styled(Button)`
    background-color: var(--bicollab-secondary-color-orange-700, #f16622);
    color: var(--bicollab-black-white-white, #fff);
    border: none;
  `;
  

export default Inspection;