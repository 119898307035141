import React from 'react';
import {
    FaArrowLeft,
    FaCalendarDays,
    FaChevronDown,
} from 'react-icons/fa6';

import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from "antd";

const NewInspectionForm = () => {
    const navigate = useNavigate()
    return (
        <div className='mt-8'>
            <div className='text-[#002C57] text-[30px]'>
            New Inspection
            </div>

            <div className='text-[#F16622] flex text-2xl gap-2 mt-10 cusror-pointer' onClick={()=>{
          navigate(-1)
        }}>
                <FaArrowLeft className='mt-1' />

                <div >Back</div>
            </div>

            <div className='mt-10'>
                <div className='text-[#002C57] text-lg font-semibold'>
                    Primary Information
                </div>
                <div className='flex gap-4'>

                    <div className='relative'>
                        <div className='text-[#767676] font-medium text-base mb-4'>
                            Inspection From Date
                        </div>
                        <DatePicker
                           
                            className='border border-gray-300  p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 p-4'
                            calendarClassName='bg-white border border-gray-300  shadow-lg'
                           
                        />
                        <FaCalendarDays className='text-[#F16622] right-3 top-14 text-lg absolute'/>
                    </div>

                    <div className='relative'>
                        <div className='text-[#767676] font-medium text-base mb-4'>
                            Inspection To Date
                        </div>
                        <DatePicker
                           
                            className='border border-gray-300  p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 p-4'
                            calendarClassName='bg-white border border-gray-300  shadow-lg'
                           
                        />
                        <FaCalendarDays className='text-[#F16622] right-3 top-14 text-lg absolute'/>
                    </div>

                    <div className='relative'>
                        <div className='text-[#767676] font-medium text-base mb-4'>
                        Inspection Facility
                        </div>
                        <select className='appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[215px]'>
                            <option value=''>All</option>
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option3'>Option 3</option>
                        </select>
                        <FaChevronDown className='h-5 w-5 text-[#F16622] absolute right-3 top-14' />
                        </div>

                        <div className='relative'>
                        <div className='text-[#767676] font-medium text-base mb-4'>
                        Inspection Facility
                        </div>
                        <select className='appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[215px]'>
                            <option value=''>All</option>
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option3'>Option 3</option>
                        </select>
                        <FaChevronDown className='h-5 w-5 text-[#F16622] absolute right-3 top-14' />
                        </div>


                        <div className='relative'>
                        <div className='text-[#767676] font-medium text-base mb-4'>
                        Program Type
                        </div>
                        <select className='appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[215px]'>
                            <option value='' disabled selected>Select</option>
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option3'>Option 3</option>
                        </select>
                        <FaChevronDown className='h-5 w-5 text-[#F16622] absolute right-3 top-14' />
                        </div>

                        <div className='relative'>
                        <div className='text-[#767676] font-medium text-base mb-4'>
                        Audit Agency
                        </div>
                        <select className='appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[215px]'>
                            <option value=''>All</option>
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option3'>Option 3</option>
                        </select>
                        <FaChevronDown className='h-5 w-5 text-[#F16622] absolute right-3 top-14' />
                        </div>

                        <div className='relative'>
                        <div className='text-[#767676] font-medium text-base mb-4'>
                        Audit Type
                        </div>
                        <select className='appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-[215px]'>
                            <option value=''>Licensing</option>
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option3'>Option 3</option>
                        </select>
                        <FaChevronDown className='h-5 w-5 text-[#F16622] absolute right-3 top-14' />
                        </div>

                      
                </div>
                

                <div className='flex gap-4 w-full mt-6'>
                    <div className='w-1/2'>
                        <div className='text-[#767676] font-medium text-base mb-4 mb-4'>
                            Inspector
                        </div>
                        <input className='w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]' />
                    </div>
                    <div className='w-1/2'>
                        <div className='text-[#767676] font-medium text-base mb-4 mb-4'>
                           Additional  Inspector
                        </div>
                        <input className='w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]' />
                    </div>
                </div>


                <div className='border-t border-t-gray-300 mt-4'></div>

                <div className='mt-10 relative'>
                <div className='text-[#002C57] text-lg font-semibold'>
                    Secondary Information
                </div>

                <div className='text-[#767676] font-medium text-base mb-4 mt-4'>
                           Add Citation
                        </div>
                <select className='appearance-none border border-gray-300  p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622] w-full'>
                            <option value='' className='' disabled selected>Select Citations</option>
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option3'>Option 3</option>
                        </select>
                        <FaChevronDown className='h-5 w-5 text-[#F16622] absolute right-3 top-20' />
                        <div
                    className=' px-8  border border-orange-600 rounded-3xl cursor-pointer font-semibold mt-4 w-1/6 flex'
                    >
                    <div>Customer:</div>
                    <div className='font-semibold text-base'>Giacomo G... </div>
                    <div>
                        <IoMdClose className='text-orange-600 text-base ml-4'/>
                    </div>
                    </div>

                    <div className='text-[#767676] font-medium text-base mt-4 mb-4'>
                           Input the individual who's record is being inspected , if applicable
                        </div>
                        <div className='border-t border-t-gray-300 mt-4'></div>

                 
                </div>



                <div className='text-[#002C57] text-lg font-semibold mt-6'>
                    Additional Information
                </div>
                <div className='flex gap-4 w-full mt-6'>

                    <div className='w-full'>
                        <div className='text-[#767676] font-medium text-base mb-4 mb-4'>
                            Comments/Follow-up Comments
                        </div>
                        <input className='w-full border border-gray-300 p-4 pr-10 focus:outline-none focus:ring-2 focus:ring-[#F16622]'  placeholder='Type In'/>
                    </div>
                </div>


                <div className='flex mt-6 gap-4 mb-4'>
                <div
                    // onClick={navigate("/newinspectionform")}
                    className='py-3 px-8  border border-orange-600 text-orange-600 rounded-3xl cursor-pointer font-semibold'
                    >
                    Cancel
                    </div>

                <div
                    // onClick={navigate("/newinspectionform")}
                    className='py-3 px-10 mr-20 bg-orange-600 text-white rounded-3xl hover:bg-orange-700 cursor-pointer font-semibold'
                    >
                    Add
                    </div>
                </div>
                    
            </div>
        </div>
    );
};

export default NewInspectionForm;
