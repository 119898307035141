import React, { useState,useEffect,useMemo } from 'react';

import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import Footer from "./Footer";
import Header from './Header';
import { useUser } from './UserContext';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { FaFloppyDisk, FaArrowLeft } from 'react-icons/fa6';
import { DatePicker } from "antd";

function AdditionalActions() {
  return (
    <Columnn>
      <NewCitation />
      <NavigationBar/>
      <AdditionalInformation />
    </Columnn>
  );
}

const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  width: 110%;
  gap: 20px; // Adjust the vertical gap between MyComponent1 and MyComponent3
`;





function NewCitation() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { userDetails } = useUser();
  
    const toggleModal = () => {
      setModalIsOpen(!modalIsOpen);
    };
  
    return (
      <div>
        <Header1>
          <Title>New Citation</Title>
  
        </Header1>
  
      </div>
    );
  }
  
  
  const Header1 = styled.header`
  display: flex;
  padding: 10px; // Adjusted padding for the header
  margin-right: -100px; // Ensure this is actually needed, otherwise remove
  margin-top: 20px;
  margin-left:-170px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  `;
  
  
  const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-primary-color-blue-900, #002c57);
  `;
  
  const NewCitation1 = styled.button`
  padding: 10px 18px;
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-black-white-white, #fff);
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 790px;
  `;


//   const NavigationItem = ({ text, isActive }) => (
//     <NavItem isActive={isActive}>{text}</NavItem>
//   );
  
//   function NavigationBar() {
//     const navItems = [
//       { text: "Citation", isActive: true },
//       { text: "Provider Plan of Action", isActive: false },
//       { text: "Additional Corrective Actions", isActive: false },
//       { text: "Review", isActive: false },
//     ];
  
//     return (
//         <SearchContainer>
//       <nav className="navigation-bar">
//         {navItems.map((item, index) => (
//           <NavigationItem key={index} text={item.text} isActive={item.isActive} />
//         ))}
//       </nav>
//      </SearchContainer>
//     );
//   }
// Component for each navigation item
const NavigationItem = ({ text, isActive, onClick }) => (
    <NavItem isActive={isActive} onClick={onClick}>
      {text}
    </NavItem>
  );
  
  // Navigation bar component with dynamic active state management
  function NavigationBar() {
    const [activeIndex, setActiveIndex] = useState(0); // State to track the active item
    const [displayText, setDisplayText] = useState(""); // State to track text to display when item is clicked
    const navigate = useNavigate();

    const navItems = [
      { text: "Citation", route:"/newcitation"},
      { text: "Provider Plan of Action",route: "/provider-plan"  },
      { text: "Additional Corrective Actions"},
      { text: "Review",route: "/review" },
    ];
    const handleItemClick = (index, text, route) => {
        setActiveIndex(index);
        setDisplayText(`You clicked on: ${text}`);
        navigate(route);
      };
    // const handleItemClick = (index, text) => {
    //   setActiveIndex(index);  // Update the active index to the clicked item's index
    //   setDisplayText(`You clicked on: ${text}`);  // Update the display text
    // };
  
    return (
        <SearchContainer>
        <nav className="navigation-bar">
          {navItems.map((item, index) => (
            <NavigationItem
              key={index}
              text={item.text}
              isActive={index === activeIndex}  // Determine if the item is active based on index
            onClick={() => handleItemClick(index, item.text, item.route)}

            />
          ))}
        </nav>
        <div>{displayText}</div> {/* Display text area */}
      </ SearchContainer>
    );
  }
  
  const SearchContainer = styled.section`
  border-radius: 3px;
  background-color: var(--bicollab-black-white-black-100, #f2f2f2);
  display: flex;
  gap: 20px;
  margin-top:-20px;
  margin-left: -160px;
  margin-right: -120px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  padding: 6px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

const NavItem = styled.button`
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  border-radius: 4px; // Rounded corners for a smooth box appearance
  background-color: var(--bicollab-black-white-white, #fff); // Light background
  color: ${props => props.isActive ? 'var(--bicollab-secondary-color-orange-700, #f16622)' : 'var(--bicollab-black-white-black-500, #515151)'};
  padding: 10px 15px; // Slightly increased padding for better visual spacing
  border: 2px solid var(--bicollab-black-white-grey-300, #ddd); // Visible border to emphasize the box
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1); // Subtle shadow for a 3D effect
  cursor: pointer;
  transition: all 0.3s ease; // Smooth transition for hover effects
  margin-right: 10px;
  &:hover {
    background-color: var(--bicollab-grey-200, #f7f7f7); // Change background on hover
    box-shadow: 0px 4px 8px rgba(0,0,0,0.15); // Increase shadow on hover for a "lifted" effect
  }

  @media (max-width: 991px) {
    white-space: normal; // Ensures text wraps properly in smaller screens
  }
`;

  const StyledNavigationBar = styled.nav`
    border-radius: 3px;
    background-color: var(--bicollab-black-white-black-100, #f2f2f2);
    display: flex;
    gap: 12px;
    padding: 6px 14px;
  
    @media (max-width: 991px) {
      flex-wrap: wrap;
      padding-right: 20px;
    }
  `;



// const AdditionalInformation = () => {
//   const correctionTypes = [
//     { label: "Corrective Action Type", placeholder: "Select" },
//     { label: "Corrective Action Category", placeholder: "Select" },
//     { label: "Corrective Action Due Date", placeholder: "Select" },
//     { label: "Corrective Action Completion Date", placeholder: "Select" }
//   ];

//   return (
//     <Container>
//       <Header2>Additional Information</Header2>
//       <CorrectionTypeContainer>
//         {correctionTypes.map((type, index) => (
//           <CorrectionTypeField key={index} label={type.label} placeholder={type.placeholder} />
//         ))}
//       </CorrectionTypeContainer>
//       <ResponsiblePartyField />
//       <DescriptionFields />
//       <CommentsField />
//       <AddButton>Add+</AddButton>
//       <ActionButtons />
//     </Container>
//   );
// };

// const CorrectionTypeField = ({ label, placeholder }) => (
//   <FieldWrapper>
//     <FieldLabel>{label}</FieldLabel>
//     <SelectWrapper>
//       <SelectPlaceholder>{placeholder}</SelectPlaceholder>
//       <SelectIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
//     </SelectWrapper>
//   </FieldWrapper>
// );

// const ResponsiblePartyField = () => (
//   <>
//     <FieldLabel>Responsible Party</FieldLabel>
//     <InputField placeholder="Enter a name or email address" />
//   </>
// );

// const DescriptionFields = () => (
//   <DescriptionContainer>
//     <DescriptionColumn>
//       <FieldLabel>Describe Citation/Violation of Regulation</FieldLabel>
//       <TextArea placeholder="Type in" />
//     </DescriptionColumn>
//     <DescriptionColumn>
//       <FieldLabel>Corrective Action Required By Inspector</FieldLabel>
//       <TextArea placeholder="Type in" />
//     </DescriptionColumn>
//   </DescriptionContainer>
// );

// const CommentsField = () => (
//   <>
//     <FieldLabel>Comments/Follow-up Comments</FieldLabel>
//     <TextArea placeholder="Type in" />
//   </>
// );

// const ActionButtons = () => (
//   <ButtonGroup>
//     <CancelButton>Cancel</CancelButton>
//     <SaveButton>Save</SaveButton>
//     <ReviewButton>
//       <span>Review</span>
//       <ReviewIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/11a66aa01c9ed3d3ab706b1c18a13560e6d5bb36a3cd8d5d4aeb69e78f7aa86b?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
//     </ReviewButton>
//   </ButtonGroup>
// );
const AdditionalInformation = () => {
    const [formData, setFormData] = useState({
      correctiveActionType: '',
      correctiveActionCategory: '',
      correctiveActionDueDate: null,
      correctiveActionCompletionDate: null,
      responsibleParty: '',
      describeCitation: '',
      correctiveActionRequired: '',
      comments: ''
    });
  
    const handleDateChange = (field, date) => {
      setFormData((prev) => ({ ...prev, [field]: date }));
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
  
    const handleSave = async () => {
      console.log('Save clicked');
      console.log(formData);
    };
  
    return (
      <Container>
        <Header2>Additional Information</Header2>
        <CorrectionTypeContainer>
          <CorrectionTypeField 
            label="Corrective Action Type" 
            name="correctiveActionType"
            value={formData.correctiveActionType}
            onChange={handleChange}
          />
          <CorrectionTypeField 
            label="Corrective Action Category" 
            name="correctiveActionCategory"
            value={formData.correctiveActionCategory}
            onChange={handleChange}
          />
          <DatePickerField
            label="Corrective Action Due Date"
            selected={formData.correctiveActionDueDate}
            onChange={(date) => handleDateChange('correctiveActionDueDate', date)}
          />
          <DatePickerField
            label="Corrective Action Completion Date"
            selected={formData.correctiveActionCompletionDate}
            onChange={(date) => handleDateChange('correctiveActionCompletionDate', date)}
          />
        </CorrectionTypeContainer>
        <ResponsiblePartyField 
          name="responsibleParty"
          value={formData.responsibleParty}
          onChange={handleChange}
        />
        <DescriptionFields 
          describeCitation={formData.describeCitation}
          correctiveActionRequired={formData.correctiveActionRequired}
          handleChange={handleChange}
        />
        <CommentsField 
          name="comments"
          value={formData.comments}
          onChange={handleChange}
        />
        <AddButton>Add+</AddButton>
        <ActionButtons handleSave={handleSave} />
      </Container>
    );
  };
  
  const CorrectionTypeField = ({ label, name, value, onChange }) => (
    <FieldWrapper>
      <FieldLabel>{label}</FieldLabel>
      <SelectWrapper>
        <SelectPlaceholder>{label}</SelectPlaceholder>
        <select name={name} value={value} onChange={onChange}>
          <option value="">Select</option>
          <option value="Option 1">Option 1</option>
          <option value="Option 2">Option 2</option>
        </select>
        <SelectIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
      </SelectWrapper>
    </FieldWrapper>
  );
  
  const DatePickerField = ({ label, selected, onChange }) => (
    <FieldWrapper>
      <FieldLabel>{label}</FieldLabel>
      <DatePickerWrapper>
        <DatePicker
          selected={selected}
          onChange={onChange}
          dateFormat="yyyy/MM/dd"
          placeholderText="Select Date"
        />
      </DatePickerWrapper>
    </FieldWrapper>
  );
  
  const ResponsiblePartyField = ({ name, value, onChange }) => (
    <>
      <FieldLabel>Responsible Party</FieldLabel>
      <InputField name={name} value={value} placeholder="Enter a name or email address" onChange={onChange} />
    </>
  );
  
  const DescriptionFields = ({ describeCitation, correctiveActionRequired, handleChange }) => (
    <DescriptionContainer>
      <DescriptionColumn>
        <FieldLabel>Describe Citation/Violation of Regulation</FieldLabel>
        <TextArea name="describeCitation" value={describeCitation} placeholder="Type in" onChange={handleChange} />
      </DescriptionColumn>
      <DescriptionColumn>
        <FieldLabel>Corrective Action Required By Inspector</FieldLabel>
        <TextArea name="correctiveActionRequired" value={correctiveActionRequired} placeholder="Type in" onChange={handleChange} />
      </DescriptionColumn>
    </DescriptionContainer>
  );
  
  const CommentsField = ({ name, value, onChange }) => (
    <>
      <FieldLabel>Comments/Follow-up Comments</FieldLabel>
      <TextArea name={name} value={value} placeholder="Type in" onChange={onChange} />
    </>
  );
  
  const ActionButtons = ({ handleSave }) => (
    <ButtonGroup>
      <CancelButton><FaArrowLeft style={{ color: '#ffad1c', margin: '0 25px' }} /></CancelButton>
      <SaveButton onClick={handleSave}>
        <FaFloppyDisk style={{ color: '#fff', margin: '0 25px' }}/>

      </SaveButton>
      <ReviewButton>
        <span>Review</span>
        <ReviewIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/11a66aa01c9ed3d3ab706b1c18a13560e6d5bb36a3cd8d5d4aeb69e78f7aa86b?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
      </ReviewButton>
    </ButtonGroup>
  );
  
const Container = styled.section`
  // border-radius: 2px;
  // display: flex;
  // flex-direction: column;
  // padding: 17px 11px;
  // border-radius: 2px;
    // padding: 17px 11px;
    // line-height: 1.14;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    padding: 17px 11px;
    margin: 0 auto; // Center the form container
    // box-sizing: border-box;
    width:120%;
    margin-left: -150px;
`;
const DatePickerWrapper = styled.div`
  justify-content: center;
  border-radius: 2px;
  border: 1px solid rgba(187, 187, 188, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  display: flex;
  `;

const Header2 = styled.h2`
  color: var(--bicollab-primary-color-blue-900, #002c57);
  letter-spacing: 0.4px;
  font: 500 14px/114% Ubuntu, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CorrectionTypeContainer = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldLabel = styled.label`
  color: #767676;
  text-align: center;
  font: 500 14px Ubuntu, sans-serif;
`;

const SelectWrapper = styled.div`
  justify-content: center;
  border-radius: 2px;
  border: 1px solid rgba(187, 187, 188, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  display: flex;
  margin-top: 13px;
  gap: 10px;
  font-size: 16px;
  color: var(--bicollab-black-white-black-300, #bbbbbc);
  font-weight: 400;
  white-space: nowrap;
  padding: 10px 21px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const SelectPlaceholder = styled.span`
  font-family: Ubuntu, sans-serif;
  margin: auto 0;
`;

const SelectIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const InputField = styled.input`
  justify-content: center;
  border-radius: 2px;
  border: 1px solid rgba(187, 187, 188, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  align-self: start;
  margin-top: 13px;
  color: var(--bicollab-black-white-black-300, #bbbbbc);
  padding: 13px 21px;
  font: 400 16px Ubuntu, sans-serif;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    max-width: 100%;
  }
`;

const DescriptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 32px;
  }
`;

const TextArea = styled.textarea`
  justify-content: center;
  border-radius: 2px;
  border: 1px solid rgba(187, 187, 188, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  margin-top: 13px;
  color: var(--bicollab-black-white-black-300, #bbbbbc);
  padding: 19px 21px;
  font: 400 16px Ubuntu, sans-serif;
  width: 100%;
  resize: vertical;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const AddButton = styled.button`
  color: var(--bicollab-secondary-color-orange-700, #f16622);
  letter-spacing: 0.4px;
  margin-top: 20px;
  font: 500 14px/114% Ubuntu, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ButtonGroup = styled.div`
  align-self: start;
  display: flex;
  margin-top: 58px;
  gap: 13px;
  font-size: 20px;
  color: var(--bicollab-secondary-color-orange-700, #f16622);
  font-weight: 500;
  white-space: nowrap;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Button = styled.button`
  font-family: Ubuntu, sans-serif;
  justify-content: center;
  border-radius: 50px;
  padding: 10px 18px;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const CancelButton = styled(Button)`
  border: 1px solid rgba(241, 102, 34, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  color: var(--bicollab-secondary-color-orange-700, #f16622);
`;

const SaveButton = styled(Button)`
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);
  color: var(--bicollab-black-white-white, #fff);
  border: none;
`;

const ReviewButton = styled(Button)`
  border: 1px solid rgba(241, 102, 34, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  color: var(--bicollab-secondary-color-orange-700, #f16622);
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ReviewIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  fill: #fff;
`;

export default AdditionalActions;