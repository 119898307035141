// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }`, "",{"version":3,"sources":["webpack://./src/views/DataAdministration/DataManager.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,SAAS;IACT,eAAe;EACjB","sourcesContent":["\n.editable-row .ant-form-item-explain {\n    position: absolute;\n    top: 100%;\n    font-size: 12px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
