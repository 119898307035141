import { Card, Col, Row } from "antd";

const Announcements = () => {


    return (
        <div style={{padding: '20px'}}>
            <p style={{paddingBottom: '15px'}}>
            We are excited to announce the launch of <b>MVP1.0</b> of our SaaS application, specially designed to meet the unique data reporting needs of Human Health Services providers. After months of development and close collaboration with experts in the field, we are now entering the <b>pilot testing</b> phase. In this initial stage, a select group of clients will have early access to the platform, and their feedback will play a crucial role in shaping the final version of the product.
            </p><p style={{paddingBottom: '15px'}}>
            Our platform offers comprehensive data reporting solutions, utilizing PowerBI’s powerful analytics and visualizations. This pilot phase is critical in refining our tools to ensure they deliver accurate, actionable insights to support your service delivery, optimize operations, and improve client outcomes.
            </p><p style={{paddingBottom: '15px'}}>
            During the pilot, you can expect the following features:
            </p><h1 style={{ fontWeight: 'bolder', paddingBottom: '10px'}}>Data Dashboards</h1><p style={{paddingBottom: '15px'}}>
                
                Real-time access to your Human Health Services data, presented in easy-to-digest visual formats.
            </p><h1 style={{ fontWeight: 'bolder', paddingBottom: '10px'}}>Seamless Integration</h1><p style={{paddingBottom: '15px'}}>
                
                Embed PowerBI reports directly into your daily workflows, making insights available with just a few clicks.
            </p><h1 style={{ fontWeight: 'bolder', paddingBottom: '10px'}}>User Management</h1><p style={{paddingBottom: '15px'}}>
                
                Manage users and their access to reports in an easy to use interface.
            </p><p style={{paddingBottom: '15px'}}>
                We value your participation and feedback. It will help us fine-tune the product before we roll it out to a broader audience. Our team is here to support you every step of the way as you explore the platform. Stay tuned for updates, and feel free to reach out to our support team for any assistance!
            </p>
        </div>
    );
};

export default Announcements;