import React from "react";
import { FaFilePen } from "react-icons/fa6";

const CitationTable = () => {
  return (
    <>
      <p className="text-header primary-color pl-40">Search Results (3)</p>
      <table className="w-full table-fixed ">
        <thead>
          <tr className="border border-b-[#767676] text-[#767676] text-[12px] border-transparent mb-2">
            <td className="py-4">Inspection Facilty ID</td>
            <td className="py-4">Customer Name</td>
            <td className="py-4">Audit Date</td>
            <td className="py-4">Reviewer</td>
            <td className="py-4  ">Status</td>
            <td className="py-4  ">Actions</td>
          </tr>
        </thead>

        <tbody className="">
          <tr className=" transparent-bg">
            <td className="py-4"> #2541 Lorem ipsum</td>
            <td className="py-4">Giacomo Guilizzoni</td>
            <td className="py-4">12/3/2022</td>
            <td className="py-4">Marco Batton</td>
            <td className="py-4  secondary-color font-400">
              {" "}
              <div className="bg-[#FFCD03]  w-40 text-base text-[#1C1C1C] font-bold rounded py-1 pl-10 ">
                In Progress
              </div>
            </td>
            <td className="flex gap-2">
              {" "}
              <div className="action-cell mt-4">
                <FaFilePen className="secondary-color mr-2 h-7 w-7 " />
              </div>
            </td>
          </tr>

          <tr className=" transparent-bg">
            <td className="py-4"> #2541 Lorem ipsum</td>
            <td className="py-4">Giacomo Guilizzoni</td>
            <td className="py-4">12/3/2022</td>
            <td className="py-4">Marco Batton</td>
            <td className="py-4  secondary-color font-400">
              {" "}
              <div className="bg-[#4A9ECF] w-40 text-base text-[#1C1C1C] font-bold rounded py-1 pl-1">
                Sent Back to Review
              </div>
            </td>
            <td className="flex gap-2">
              {" "}
              <div className="action-cell mt-4">
                <FaFilePen className="secondary-color mr-2 h-7 w-7 " />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default CitationTable;