
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import execute from '../hooks/execute';
import { protectedResources } from '../config/msalConfig';

// Styled components for the modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  z-index: 1051;
`;

const ModalHeader = styled.h2`
  margin-top: 0;
`;

const ModalForm = styled.form`
  .error {
    border: 1px solid #f00;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 0 0 0 5px;
`;

const FormLabel = styled.label`
  margin-top: 10px;
  font-weight: bold;
  .error {
    border: 1px solid #f00;
}
`;

const FormInput = styled.input`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const EmailError = styled.span`
  color: red;
`;
const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }

  &:first-child {
    background-color: #6c757d;
    &:hover {
      background-color: #5a6268;
    }
  }
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  margin-top: 5px;
`;

const EditUserModal = ({ custDomain, isOpen, onClose, userData, onSave, setDeleteModalOpen }) => {
  const [user, setUser] = useState({});
  const [originalUser, setOriginalUser] = useState({});
  const [modulesAssigned, setModulesAssigned] = useState({});
  const [moduleMap, setModuleMap] = useState({});
  const [emailError, setEmailError] = useState(null);
  const [assignedRoles, setAssignedRoles] = useState({
    'East Area 1': false,
    'South Area 1': false,
    'South Area 2': false,
    'South Area 3': false,
    'East Area 2': false,
  });

  useEffect(() => {
    if (userData) {
      setUser(userData);
      setOriginalUser(userData); // Save the original user data for comparison

      // Fetch modules assigned to the user and module names
      fetchModules(userData.Email).then(modules => {
        fetchAllModules().then(allModules => {
          const moduleMapState = allModules.reduce((acc, module) => {
            acc[module.ShortName] = module.ModuleName;
            return acc;
          }, {});
          setModuleMap(moduleMapState);

          const modulesState = modules
          .filter(module => module in moduleMapState)
          .reduce((acc, module) => {
            acc[module] = userData.Modules.includes(module);
            return acc;
          }, {});
          setModulesAssigned(modulesState);
        });
      });

      if (userData.Role) {
        const rolesArray = userData.Role.split(',');
        const rolesObject = rolesArray.reduce((acc, roleName) => {
          acc[roleName.trim()] = true;
          return acc;
        }, {});
        setAssignedRoles({...assignedRoles, ...rolesObject});
      }
    }
  }, [userData]);

  const fetchModules = async (userEmail) => {
    try {
      const response = await execute('GET', `${protectedResources.api.baseEndPoint}/customerUsers/ByEmail/${userEmail}/organization`);
      let modules = response.data.Modules;

      // Ensure modules is an array
      if (!Array.isArray(modules)) {
        modules = modules.split(',').map(module => module.trim());
      }
      console.log('fetchModules:', modules);
      return modules;
    } catch (error) {
      console.error('Error fetching modules:', error);
      return [];
    }
  };

  const fetchAllModules = async () => {
    try {
      const response = await execute('GET',`${protectedResources.api.baseEndPoint}/modules`);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error fetching all modules:', error);
      return [];
    }
  };
  const ValidateEmailDomain = (email) => {
    console.log('custEmail:', email);
    if (!email) {
      setEmailError('Email is required');
    }    
    //  check if email is valid and suffix with domain
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('Invalid email format');
    } else if (email && custDomain && !email.endsWith(custDomain)) {
      setEmailError('Email domain should match with the customer Domain: ' + custDomain);            
    } else {
      setEmailError(null);
      return true;
    }
    return false;
  };
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === 'checkbox') {
      if (name in modulesAssigned) {
        setModulesAssigned((prev) => ({
          ...prev,
          [name]: checked
        }));
      } else if (name in assignedRoles) {
        setAssignedRoles((prev) => ({
          ...prev,
          [name]: checked
        }));
      } else {
        setUser((prev) => ({
          ...prev,
          [name]: checked
        }));
      }
    } else {
      if (name === 'Email') {
        ValidateEmailDomain(value);
      }
      setUser((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email
    if (!ValidateEmailDomain(user.Email)) {
      alert('email domain must match the customer domain: ' + custDomain);
      return;
    }

    const roleValue = user.Role;
    const activeModules = Object.keys(modulesAssigned).reduce((acc, key) => {
      if (modulesAssigned[key]) {
        acc[key] = true;
      }
      return acc;
    }, {});
    const activeRoles = Object.keys(assignedRoles).reduce((acc, role) => {
      if (assignedRoles[role]) {
        acc[role] = true;
      }
      return acc;
    }, {});

    // Determine modified fields
    const modifiedFields = Object.keys(userData).filter(
      (key) => userData[key] !== user[key]
    );

    try {
      const response = await execute('PUT', `${protectedResources.api.baseEndPoint}/customerUsers`, {
        ...user,
        UserId: userData.UserId,
        Role: roleValue,
        modifiedFields,
        activeModules,
        activeRoles
      });
      if(response.success){
        onSave(user);
        onClose();
        alert('User updated successfully!');
      }else{
        alert('Failed to update user: ' + response.message);
      }
    } catch (error) {
      console.error('Failed to update user:', error);
      alert('Error updating user: ' + error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>Edit User</ModalHeader>
        <ModalForm onSubmit={handleSubmit}>
          <FormRow>
            <FormColumn className="left-column">
              <FormLabel htmlFor="firstName">First Name</FormLabel>
              <FormInput
                id="firstName"
                name="FirstName"
                value={user.FirstName || ''}
                onChange={handleChange}
              />
              <FormLabel htmlFor="email">Email </FormLabel>{/*[someemail@{custDomain}] */}
              <FormInput
                className={emailError && 'error'} // Add error class if email is invalid
                type="email"
                id="email"
                name="Email"
                disabled="true"
                value={user.Email || `@${custDomain}` }
                onChange={handleChange}
              />
              {emailError && <EmailError>{emailError}</EmailError>}        
              <FormLabel>Modules to Assign</FormLabel>
              {Object.keys(modulesAssigned).map((module) => (
                <div key={module}>
                  <CheckboxInput
                    id={module}
                    name={module}
                    checked={modulesAssigned[module]}
                    onChange={handleChange}
                  />
                  <label htmlFor={module}>{moduleMap[module]}</label>
                </div>
              ))}

            </FormColumn>
            <FormColumn className="right-column">
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <FormInput
                id="lastName"
                name="LastName"
                value={user.LastName || ''}
                onChange={handleChange}
              />     
              <FormLabel htmlFor="isAdmin">Role</FormLabel>       
              <FormLabel htmlFor="isAdmin">Admin
              <CheckboxInput
                id="isAdmin"
                name="IsAdmin"
                checked={user.IsAdmin || false}
                onChange={handleChange}
              /></FormLabel>
              {/* <FormLabel>Assign Roles</FormLabel>
              {Object.keys(assignedRoles).map((role) => (
                <div key={role}>
                  <CheckboxInput
                    id={role}
                    name={role}
                    checked={assignedRoles[role]}
                    onChange={handleChange}
                  />
                  <label htmlFor={role}>{role}</label>
                </div>
              ))} */}
            </FormColumn>
          </FormRow>
          <ButtonsRow>
            <Button type="submit">Save Changes</Button>
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
          </ButtonsRow>
        </ModalForm>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default EditUserModal;
