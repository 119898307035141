import { Tabs } from "antd";
import React from "react";
import styled from "styled-components";
import Announcements from "./Announcements";
import QuickTips from "./QuickTips";
import ProductNews from "./ProductNews";
import { FaBullhorn, FaLightbulb, FaRadio } from "react-icons/fa6";

const tabItems = [
  {
    key: '1',
    label: (<b>Announcement</b>),
    children: <Announcements />,
    icon: <FaBullhorn />
  },
  {
    key: '2',
    label: (<b>Quick Tips</b>),
    children: <QuickTips />,
    icon: <FaLightbulb />
  },
  {
    key: '3',
    label: (<b>Product News</b>),
    children: <ProductNews />,
    icon: <FaRadio />
  },
];

function InformationSection() {


  return (
    <Container>
      <Tabs
      defaultActiveKey="1"
      type="card"
      size="small"
      items={tabItems} />        
    </Container>

  );
}
  
const Container = styled.div`
  margin: 35px 10px 10px 10px;
`;

  export default InformationSection;