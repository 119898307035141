import * as React from "react";
import styled from "styled-components";

const footerData = [
  {
    text: "Terms of Service",
    url: "/terms-of-service",
  },
  {
    text: "Privacy Policy",
    url: "/privacy-policy",
  },
];

function Footer() {
  return (
    <FooterWrapper>
      <FooterContent>
        <Copyright>© The BI Collaborative. All rights reserved.</Copyright>
        <Logo src={`${process.env.REACT_APP_SHARED_IMAGES_URL}/prism-logo.png`} alt="The BI Collaborative Logo" />
        <FooterLinks>
          {footerData.map((link, index) => (
            <FooterLink key={index} href={link.url}>
              {link.text}
            </FooterLink>
          ))}
        </FooterLinks>
      </FooterContent>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.footer`
  box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  width:100%;
`;



const FooterLink = styled.a`
  font-family: Ubuntu, sans-serif;
`;

const FooterContent = styled.div`
  background-color: var(--bicollab-black-white-white, #fff);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;  /* Ensures spacing on both ends and middle */
  padding: 6px 28px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center; /* Aligns items vertically for smaller screens */
  }
`;

const Copyright = styled.p`
  color: var(--bicollab-black-white-black-400, #767676);
  flex: 1;  /* Allows this element to grow to take necessary space */
  text-align: left;  /* Aligns the text to the left within the flexed item */
  margin: 0; /* Removes margin for alignment purposes */
  font: 12px Ubuntu, sans-serif;
`;

const Logo = styled.img`
  order: -1;  /* Ensures the logo is always in the center */
  object-fit: contain;
  width: 55px;
`;

const FooterLinks = styled.nav`
  flex: 1;  /* Similar to copyright, allows this element to grow */
  display: flex;
  gap: 16px;
  font-size: 14px;
  color: var(--bicollab-black-white-black-700, #1c1c1c);
  justify-content: flex-end; /* Aligns links to the right */
  margin: 0; /* Adjusts margin for alignment */
  padding: 10px;
`;

export default Footer;