import React from 'react';
import styled from 'styled-components';

// Styled component definition
const Title = styled.h1`
    margin: 0;
    font-size: 30px;
    font-weight: 500;
    font-family: Ubuntu, sans-serif;
    color: var(--bicollab-primary-color-blue-900, #002c57);
    white-space: nowrap; // Prevent title from wrapping
`;

const Header1 = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%; // Adjusted to full width
    margin-top: 20px;
    flex-wrap: nowrap; // Prevents wrapping of items
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
`;

const HeaderButton = styled.button`
    padding: 10px 18px;
    font-size: 20px;
    font-family: Ubuntu, sans-serif;
    color: var(--bicollab-black-white-white, #fff);
    background-color: var(--bicollab-secondary-color-orange-700, #f16622);
    border: none;
    border-radius: 4px;
    cursor: pointer;
`;

const TitleComponent = ({ title, toggleModal, buttonText, showButton = false }) => {
    return (
        <Header1>
            <Title>{title}</Title>
            {showButton && (
                <HeaderButton onClick={toggleModal}>
                    {buttonText}
                </HeaderButton>
            )}
        </Header1>
    );
};

export default TitleComponent;
