
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config/msalConfig';
import { InteractionStatus } from '@azure/msal-browser';
const PrivateRoute = ({ element: Component }) => {
    const location = useLocation();
    const { instance, inProgress } = useMsal();

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    useEffect(() => {
        if(!activeAccount && inProgress === InteractionStatus.None){
            instance.loginRedirect(loginRequest).catch((error) => console.error(error));            
        }
        //console.log("PrivateRoute: ", activeAccount, inProgress);
    }, [inProgress, instance, activeAccount]);

    return activeAccount ? (
        React.createElement(Component)
    ) : (
        <Navigate to="/" state={{ from: location }} />
    );
};

export default PrivateRoute;