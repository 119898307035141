import React, { useState,useEffect,useMemo } from 'react';

import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import InspectionTable from './InspectionTabs/InspectionTable';

function AppLayoutInspection() {
  return (
        <Columnn>
        < NewCitation/>
        <SearchBar  />
        <SearchParameter />
        <InspectionTable />
        </Columnn>
  );
}

const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px; // Adjust the vertical gap between MyComponent1 and MyComponent3
`;



function NewCitation() {
  const navigate = useNavigate()
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userDetails } = useUser();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div>
      <Header1>
        <Title> Inspection Management</Title>
        <button
        onClick={()=>{
          navigate("/newinspectionform")
        }}
      // onClick={navigate("/newinspectionform")}
      className='py-2 px-5 mr-40 bg-orange-600 text-white rounded-3xl hover:bg-orange-700 cursor-pointer'
    >
      New Inspection
    </button>
      </Header1>

    </div>
  );
}


const Header1 = styled.header`
display: flex;
justify-content: space-between;
align-items: center;
gap: 10px; // Maintain a reasonable gap between inner elements
padding: 20px; // Adjusted padding for the header
margin-top: 20px;
@media (max-width: 991px) {
  flex-wrap: wrap;
}
`;


const Title = styled.h1`
margin: 0;
font-size: 30px;
font-family: Ubuntu, sans-serif;
color: var(--bicollab-primary-color-blue-900, #002c57);
`;


const SearchBar = () => {
  const navigate = useNavigate();

  const goToNewPage = () => {
    navigate('/newinspection'); // Replace '/new-page' with the actual path you want to navigate to
  };

  return (
    <SearchContainer>
      <SearchButton>Search</SearchButton>
      <ReportSelector>
        <ReportLabel>Select Report</ReportLabel>
        <DropdownIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ead24240980d11180efd88f499655ecc641ad1aa5e35a4a4b2f398fdecd18272?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="Dropdown icon" />
        
      </ReportSelector>
      
      {/* <NewCitation1 onClick={goToNewPage}>New Inspection</NewCitation1> New button to navigate to a new page */}

    </SearchContainer>
  );
};
// const SearchContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 10px;
//   border: 1px solid #ccc;
//   width: 100%;
// `;

const SearchButton = styled.button`
  padding: 8px 12px;
  background-color: var(--bicollab-black-white-white, #fff);
  color: var(--bicollab-secondary-color-orange-700, #f16622);
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ReportSelector = styled.div`
  display: flex;
  align-items: center;
`;

const ReportLabel = styled.span`
  margin-right: 8px;
`;

const DropdownIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const NewCitation1 = styled.button`
  padding: 8px 12px;
  background-color: #28a745;
  color: var(--bicollab-black-white-white, #fff);
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto; /* Ensures it stays at the rightmost part */
`;


const SearchContainer = styled.section`
  border-radius: 3px;
  background-color: var(--bicollab-black-white-black-100, #f2f2f2);
  display: flex;
  gap: 20px;
  margin-top:-20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  padding: 6px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

// const SearchButton = styled.button`
//   font-family: Ubuntu, sans-serif;
//   border-radius: 4px;
//   background-color: var(--bicollab-black-white-white, #fff);
//   color: var(--bicollab-secondary-color-orange-700, #f16622);
//   white-space: nowrap;
//   padding: 10px;
//   border: none;
//   cursor: pointer;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const ReportSelector = styled.div`
//   border-radius: 4px;
//   background-color: var(--bicollab-black-white-white, #fff);
//   display: flex;
//   gap: 10px;
//   color: var(--bicollab-black-white-black-500, #515151);
//   padding: 10px;
//   align-items: center;
//   cursor: pointer;
// `;

// const ReportLabel = styled.span`
//   font-family: Ubuntu, sans-serif;
// `;

// const DropdownIcon = styled.img`
//   width: 18px;
//   height: 18px;
//   object-fit: contain;
// `;




// const SearchParameter = () => {
//   const searchFields = [
//     { label: "Audit Date", value: "Select", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/78f974792b86eed9bba892129d890eda3b6fb85e9481ac456ec3f4e823b564a2?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//     { label: "Audit Agency", value: "Select", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//     { label: "Audit Type", value: "Licensing", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//     { label: "Status", value: "In Process", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//     { label: "Reviewer", value: "Enter a name or email address" },
    
//     { label: "Citation Type", value: "Individual Record", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },

//   ];

//   const additionalFields = [
//     { label: "Location", value: "0040 - 831 Bower Hill, California, USA", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/badfcab2d931c46db435172e546460fb81dd51118a2a1c62edf627225e7b36ea?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//     { label: "Customer Name", value: "Enter a name or email address" },
//     // { label: "Citation Type", value: "Individual Record", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//   ];

//   return (
//     <SearchContainer1>
//       <SearchTitle>Search Parameter</SearchTitle>
//       <SearchFieldsWrapper>
//         {searchFields.map((field, index) => (
//           <SearchField key={index} label={field.label} value={field.value} icon={field.icon} />
//         ))}
//       </SearchFieldsWrapper>
//       <AdditionalFieldsWrapper>
//         {additionalFields.map((field, index) => (
//           <SearchField key={index} label={field.label} value={field.value} icon={field.icon} />
//         ))}
//       </AdditionalFieldsWrapper>
//       <ButtonGroup>
//         <CancelButton>Cancel</CancelButton>
//         <SearchButtonIn>Search</SearchButtonIn>
//       </ButtonGroup>
      
//       {/* <AdvancedSearchWrapper>
//         <AdvancedSearchText>Advanced Search</AdvancedSearchText>
//         <AdvancedSearchIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a976b0d3c98d4f3717e8e0088d317fa6d20a8e190874d762c384551a17efe29?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
//       </AdvancedSearchWrapper> */}
//     </SearchContainer1>
//   );
// };

// const SearchField = ({ label, value, icon }) => (
//   <FieldWrapper>
//     <FieldLabel>{label}</FieldLabel>
//     <FieldValue>
//       <span>{value}</span>
//       {icon && <FieldIcon loading="lazy" src={icon} alt="" />}
//     </FieldValue>
//   </FieldWrapper>
// );



const SearchParameter = () => {
  const [formData, setFormData] = useState({
    auditDateFrom: '',
    auditDateTo: '',
    auditAgency: 'All',
    auditType: '',
    status: '',
    citationType: 'Licensing',
    customerName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    // Send formData to the backend
    console.log(formData);
  };

  const searchFields = [
    { label: "Inspection Date From", placeholder:"Select", name: "auditDateFrom", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/78f974792b86eed9bba892129d890eda3b6fb85e9481ac456ec3f4e823b564a2?apiKey=1607f544a0344092815fcb145a04f0bb&" },
    { label: "Inspection Date To", placeholder:"Select", name: "auditDateTo", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/78f974792b86eed9bba892129d890eda3b6fb85e9481ac456ec3f4e823b564a2?apiKey=1607f544a0344092815fcb145a04f0bb&" },
    { label: "Inspection Facility", placeholder:"Select", name: "auditAgency", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
    { label: "Program Type", placeholder:"Select", name: "programType", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
    { label: "Audit Agency", placeholder:"Select", name: "status", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
  ];
  
  const additionalFields = [
    { label: "Citation Type", placeholder:"Select", name: "auditType", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
    { label: "Inspector", name: "customerName", placeholder:"Enter a name or email address", },
    { label: "Customer Name", name: "customerName", placeholder:"Enter a name or email address", },
    { label: "Citation Type", name: "citationType", placeholder:"Select",},
  ];

  return (
    <div style={{
      width: "100%",
    }
    } className="bg-gray-100 w-full p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-6 text-gray-700">Search Parameter</h2>

        {/* Search Fields */}
        <div className="grid grid-cols-5 gap-4 mb-6">
          {searchFields.map((field, index) => (
            <div key={index} className="relative col-span-1">
            <label className="block text-sm font-medium text-gray-700">{field.label}</label>
            <input
              type="text"
              placeholder={field.placeholder}
              name={field.name}
              value={formData[field.name]}
              onChange={handleChange}
              className="mt-1 p-2 pr-10 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
            <img src={field.icon} alt="" className="absolute mt-3 right-2 top-1/2 transform -translate-y-1/2 w-8 h-8 pointer-events-none" />
          </div>
        ))}
      </div>

      {/* Additional Fields */}
        <div className="grid grid-cols-4 gap-4 mb-6">
          {additionalFields.map((field, index) => (<div key={index} className="relative col-span-1">
            <label className="block text-sm font-medium text-gray-700">{field.label}</label>
            <input
              type="text"
              placeholder={field.placeholder}
              name={field.name}
              value={formData[field.name]}
              onChange={handleChange}
              className="mt-1 p-2 pr-10 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        ))}</div>

        {/* Buttons */}
        <div className="flex justify-start space-x-4">
          <button
            type="button"
            className="py-2 px-5 bg-white-500 text-orange-500 rounded-3xl hover:bg-white-600 border border-orange-500"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="py-2 px-5 bg-orange-600 text-white rounded-3xl hover:bg-orange-700"
          >
            Search
          </button>
        </div>
      </div>
    );
  };
    // <SearchContainer1>
    //   <SearchTitle>Search Parameter</SearchTitle>
    //   <SearchFieldsWrapper>
    //     {searchFields.map((field, index) => (
    //       <SearchField
    //         key={index}
    //         label={field.label}
    //         name={field.name}
    //         value={formData[field.name]}
    //         icon={field.icon}
    //         onChange={handleChange}
    //       />
    //     ))}
    //   </SearchFieldsWrapper>
    //   <AdditionalFieldsWrapper>
    //     {additionalFields.map((field, index) => (
    //       <SearchField
    //         key={index}
    //         label={field.label}
    //         name={field.name}
    //         value={formData[field.name]}
    //         icon={field.icon}
    //         onChange={handleChange}
    //       />
    //     ))}
    //   </AdditionalFieldsWrapper>
    //   <ButtonGroup>
    //     <CancelButton>Cancel</CancelButton>
    //     <SearchButtonIn onClick={handleSubmit}>Search</SearchButtonIn>
    //   </ButtonGroup>
    // </SearchContainer1>
//   );
// };

const SearchField = ({ label, name, value, icon, onChange }) => (
  <FieldWrapper>
    <FieldLabel>{label}</FieldLabel>
    <FieldValue>
      <FieldInput name={name} value={value} onChange={onChange} />
      {icon && <FieldIcon loading="lazy" src={icon} alt="" />}
    </FieldValue>
  </FieldWrapper>
);
const FieldInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
`;
const SearchContainer1 = styled.section`
  border-radius: 2px;
  border: 1px solid rgba(187, 187, 188, 1);
  background-color: var(--bicollab-black-white-black-100, #f2f2f2);
  // display: flex;
  padding: 17px 11px;
`;

const SearchTitle = styled.h2`
  color: var(--bicollab-primary-color-blue-900, #002c57);
  letter-spacing: 0.4px;
  font: 500 18px/100% Ubuntu, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  margin-top: -9px;
`;

const SearchFieldsWrapper = styled.div`
  text-align:left;
  display: flex;
  margin-top: 24px;
  gap: 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  margin-bottom:-12px;
`;

const AdditionalFieldsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 10px;
  padding: 0 1px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
`;

const FieldLabel = styled.label`
  color: #767676;
  font: 500 14px Ubuntu, sans-serif;
`;

const FieldValue = styled.div`
  justify-content: center;
  border-radius: 2px;
  border: 1px solid rgba(187, 187, 188, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  display: flex;
  margin-top: 13px;
  gap: 10px;
  font-size: 16px;
  color: var(--bicollab-black-white-black-400, #767676);
  font-weight: 400;
  white-space: nowrap;
  padding: 10px 21px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const FieldIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const ButtonGroup = styled.div`
  align-self: start;
  display: flex;
  margin-top: 20px;
  gap: 13px;
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const CancelButton = styled.button`
  font-family: Ubuntu, sans-serif;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid rgba(241, 102, 34, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  color: var(--bicollab-secondary-color-orange-700, #f16622);
  padding: 10px 18px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const SearchButtonIn = styled.button`
  font-family: Ubuntu, sans-serif;
  justify-content: center;
  border-radius: 50px;
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);
  color: var(--bicollab-black-white-white, #fff);
  padding: 10px 18px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const AdvancedSearchWrapper = styled.div`
  justify-content: center;
  align-items: end;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  font-size: 14px;
  color: var(--bicollab-secondary-color-orange-700, #f16622);
  font-weight: 500;
  text-align: center;
  padding: 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 20px;
  }
`;

const AdvancedSearchText = styled.span`
  font-family: Ubuntu, sans-serif;
`;

const AdvancedSearchIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 17px;
`;









export default AppLayoutInspection;


