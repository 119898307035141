import styled from "styled-components";
import DataWidgetsSection from './DataWidgetsSection';
import InformationSection from './InformationSection'

function Dashboard() {            
  return (
        <Columnn>
          <DataWidgetsSection />
          <InformationSection />
        </Columnn>
  );
}
const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px; // Adjust the vertical gap between MyComponent1 and MyComponent3
  overflow: auto;
`;
export default Dashboard;
