import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import EditUserModal from './EditUserModel';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { useUser } from './UserContext';
import execute from '../hooks/execute';
import { protectedResources } from '../config/msalConfig';
import { FaFilePen, FaEnvelope, FaTrash } from 'react-icons/fa6';
import { App, message } from 'antd';
import UserManagementTable from 'views/UserManagement/UserManagementTable';


function AppLayoutUser() {
  const { userDetails } = useUser();
  const [searchResults, setSearchResults] = useState(null);
  const [refreshData, setRefreshData] = useState(false); // New state to trigger data refresh
  const [custDomain, setCustDomain] = useState('');

  useEffect(() => {
    if (userDetails && userDetails.email) {
      console.log('User Email:', userDetails.email);

      Promise.all([
        execute('GET',`${protectedResources.api.baseEndPoint}/customers/ByEmail/${userDetails.email}`)
      ])
        .then(responses => {
          const customerData = responses[0].data;
          setCustDomain(customerData.Domain);
          console.log('Customer Data:', customerData);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [userDetails]);

  const handleUserAdded = () => {
    setRefreshData(!refreshData); // Toggle refreshData to trigger data refetch
  };

  return (

        <Columnn>
          <OrganizationsPage custDomain={custDomain} onUserAdded={handleUserAdded} />
          {/* <SearchParameterForm setSearchResults={setSearchResults} /> */}
          {/* <Data custDomain={custDomain} searchResults={searchResults} refreshData={refreshData} setRefreshData={setRefreshData} /> */}
          <UserManagementTable userDetails={userDetails} custDomain={custDomain} Modal={NewOrganizationModal}/>
        </Columnn>
  );
}

const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px; // Adjust the vertical gap between MyComponent1 and MyComponent3
`;

const Modal = styled.div`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background: white;
padding: 30px;
z-index: 2;
width: 80%;
max-width: 600px;
border: 1px solid #ccc;
margin-bottom: 100px;
border-radius: 8px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Overlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
z-index: 1;
`;


const ModalForm = styled.form`
  .error {
    border: 1px solid #f00;
  }
  .success {
    border: 1px solid #0f0;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 0 0 0 5px;
`;


const FormLabel = styled.label`
  margin-top: 10px;
  font-weight: bold;
  .error {
      border: 1px solid #f00;
  }
`;

const Input = styled.input`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const EmailError = styled.span`
  color: red;
`;

const Button = styled.button`
padding: 10px 20px;
font-size: 16px;
background-color: #f60; // Orange background
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.2s;
&:hover {
  background-color: #e55; // Darker orange on hover
}
`;

const CheckboxGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;

    & > div {
        margin-bottom: 10px;
    }

    input[type="checkbox"] {
        margin-right: 10px;
    }

  
    label {
        cursor: pointer;
    }
    `;
const ButtonsRow = styled.div`
display: flex;
justify-content: center;  // Centers the buttons horizontally
gap: 10px;                // Spacing between buttons
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  z-index: 1051;
`;

const ModalHeader = styled.h2`
  margin-top: 0;
`;

const FormInput = styled.input`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;


const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  margin-top: 5px;
`;



const Select = styled.select`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const NewOrganizationModal = ({ custDomain, isOpen, onClose, userEmail, onUserAdded }) => {
  const { userDetails } = useUser();

  const [organizationName, setOrganizationName] = useState('');
  const [address, setAddress] = useState('');
  const [domain, setDomain] = useState('');
  const [adminEmail, setAdminEmail] = useState('@'+custDomain);
  const [emailError, setEmailError] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({ Customer_ID: '', CustomerName: '' });
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [modulesMap, setModulesMap] = useState({});
  const [modulesAssigned, setModulesAssigned] = useState({});
  const [selectedRoles, setSelectedRoles] = useState({
    'East Area 1': false,
    'South Area 1': false,
    'South Area 2': false,
    'South Area 3': false,
    'East Area 2': false,
  });
  const [isOrgUser, setIsOrgUser] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [role, setRole] = useState('Regular User'); // New state for role dropdown    
  const [ customerDomain, setCustomerDomain] = useState(''); // New state for customer domain

  const resetForm = () => {
    setOrganizationName('');
    setAddress('');
    setDomain('');
    setAdminEmail('');
    setCustomers([]);
    setSelectedCustomer({ Customer_ID: '', CustomerName: '' });
    setLoading(false);
    setModules([]);
    setModulesMap({});
    setModulesAssigned({});
    setSelectedRoles({
      'East Area 1': false,
      'South Area 1': false,
      'South Area 2': false,
      'South Area 3': false,
      'East Area 2': false,
    });
    setIsOrgUser(false);
    setOrgId(null);
    setRole('Regular User');
  };

  useEffect(() => {
    if (isOpen && custDomain) {
      setCustomerDomain(custDomain);
      console.log('custDomain:', custDomain);
      setAdminEmail('@'+custDomain);
    }
  }, [isOpen, custDomain]);

  useEffect(() => {
    if (isOpen) {
      resetForm();

      execute('GET', `${protectedResources.api.baseEndPoint}/organizations/ByEmail/${userEmail}`)
        .then(response => {
          const { success, data } = response;
          console.log('Organization details:', response);
          setIsOrgUser(success);
          setOrgId(data.Org_ID);

          if (success && data.Org_ID) {
            fetchCustomers(data.Org_ID);
          }
        })
        .catch(error => console.error('Error fetching user details:', error));

      fetchModuleDetails(userEmail);
    }
  }, [userEmail, isOpen]);

  const fetchModuleDetails = async (userEmail) => {
    try {
      const modResponse = await execute('GET', `${protectedResources.api.baseEndPoint}/customerUsers/ByEmail/${userEmail}/organization`);
      console.log('modResponse:', modResponse.data); // Log response for debugging

      let fetchedModules = modResponse.data.Modules;

      // If fetchedModules is a string, split it into an array
      if (typeof fetchedModules === 'string') {
        fetchedModules = fetchedModules.split(',').map(module => module.trim());
      }

      // Ensure fetchedModules is always an array
      if (!Array.isArray(fetchedModules)) {
        fetchedModules = [];
      }
      console.log('fetchedModules:', fetchedModules);

      const modulesResponse = await execute('GET', `${protectedResources.api.baseEndPoint}/modules`);
      console.log('modulesResponse:', modulesResponse); // Log response for debugging
      const allModules = Array.isArray(modulesResponse.data) ? modulesResponse.data : [];
      console.log('allModules:', allModules);

      const modulesMap = allModules.reduce((acc, module) => {
        acc[module.ShortName] = module.ModuleName;
        return acc;
      }, {});
      console.log('modulesMap:', modulesMap);

      const initialAssignedState = fetchedModules.reduce((acc, ShortName) => {
        if (ShortName in modulesMap) {
          acc[ShortName] = false; // Ensure all are set to false initially
        } else {
          console.warn(`ShortName "${ShortName}" not found in modulesMap`);
        }
        return acc;
      }, {});
      console.log('initialAssignedState:', initialAssignedState);
      fetchedModules = fetchedModules.filter(ShortName => ShortName in modulesMap);
      setModules(fetchedModules);
      setModulesMap(modulesMap);
      setModulesAssigned(initialAssignedState);
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  };

  const fetchCustomers = (orgId) => {
    setLoading(true);
    execute('GET', `${protectedResources.api.baseEndPoint}/customers/${orgId}`)
      .then(response => {
        const fetchedCustomers = Array.isArray(response.data) ? response.data : [];
        setCustomers(fetchedCustomers);
        if (fetchedCustomers.length > 0) {
          const firstCustomer = fetchedCustomers[0];
          setSelectedCustomer({
            Customer_ID: firstCustomer.Customer_ID,
            CustomerName: firstCustomer.CustomerName
          });
          setCustomerDomain(firstCustomer.Domain);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching customers:', error);
        setLoading(false);
      });
  };

  const onEmailChange = async (email) => {
    setAdminEmail(email);
    await ValidateEmailDomain(email);
  };

  const checkIfUserWithEmailExists = async (email) => {
    try {
      const response = await execute('GET',`${protectedResources.api.baseEndPoint}/customerUsers/exists/${email}`);
      if(response.success){        
        return response.data;
      }else{
        message.error(response.message || 'Failed to check if user exists');
        return { exists: false };
      }
    } catch (error) {
      console.error('Error checking if user exists:', error);
      message.error('Error checking if user exists: ' + error.message);
      return { exists: false };
    }
  };

  const ValidateEmailDomain = async (email) => {
    console.log('custEmail:', email);
    if (!email) {
      setEmailError('Email is required');
    }    
    //  check if email is valid and suffix with domain
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('Invalid email format');
    } else if (email && customerDomain && !email.endsWith(customerDomain)) {
      setEmailError('Email domain should match with the customer Domain: ' + customerDomain);            
    } else{ 
      setEmailError('Checking if user exists...');
      var emailExists = await checkIfUserWithEmailExists(email);
      if(emailExists.exists){
        setEmailError('A user with this email already exists');
      }else {
        setEmailError(null);
        return true;
      }
    }
    return false;
  };

  const handleModuleChange = (moduleName, event) => {
    setModulesAssigned(prevState => ({
      ...prevState,
      [moduleName]: event.target.checked
    }));
  };

  const handleRoleChange = (role) => {
    setSelectedRoles(prevRoles => ({
      ...prevRoles,
      [role]: !prevRoles[role]
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log({ organizationName, address, domain, adminEmail, userEmail, selectedCustomer, modulesAssigned, selectedRoles });

    if (!await ValidateEmailDomain(adminEmail)) {
      message.error('either email is invalid or already exists');
      return;
    }


    try {
      const response = await execute('POST',`${protectedResources.api.baseEndPoint}/customerUsers`, {
          organizationName,
          address,
          domain: role === 'Admin' ? 1 : 0,
          adminEmail,
          userEmail,
          selectedCustomer,
          modulesAssigned,
          selectedRoles,
          // role: role === 'Admin' ? 1 : 0 // Send 1 for Admin and 0 for Regular User
      });

      if (response.success) {        
        const data = await response.data;
        console.log('Success:', data);
        alert(`user created: ${data.Email}`);
        onClose();
        onUserAdded();
      } else {
        throw new Error(response.message || 'Failed to create user');
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`Error: ${error.message}`);
    }
  };

  if (!isOpen) return null;

  console.log('Modules:', modules);

  return (
    <>
      <Overlay onClick={onClose} />
      <Modal>
        <h2>New User</h2>
        <ModalForm onSubmit={handleSubmit}>
          <FormRow>
            <FormColumn className='left-column'>
              <FormLabel>First Name</FormLabel>
              <Input type="text" value={organizationName} onChange={e => setOrganizationName(e.target.value)} placeholder="Type in" />

                {isOrgUser && (<>
                  <FormLabel>Customer</FormLabel>
                    <Select
                      value={`${selectedCustomer.Customer_ID}|${selectedCustomer.CustomerName}`}
                      onChange={(e) => {
                        const [Customer_ID, CustomerName] = e.target.value.split('|');
                        var customer = customers.find(c => c.Customer_ID === Customer_ID*1);
                        console.log('Customer:', customer, customers);
                        setCustomerDomain(customer.Domain);
                        setSelectedCustomer({ Customer_ID, CustomerName });
                      }}
                      disabled={loading}
                    >
                      {loading ? <option>Loading...</option> : customers.map(customer => (
                        <option key={customer.Customer_ID} value={`${customer.Customer_ID}|${customer.CustomerName}`}>
                          {customer.CustomerName}
                        </option>
                      ))}
                    </Select>
                </>)}

                <FormLabel>User Email [someemail@{customerDomain}]</FormLabel>
                <Input className={adminEmail && (emailError ? 'error' : 'success')}  type="email" value={adminEmail} onChange={async e => await onEmailChange(e.target.value)} placeholder={'@' + customerDomain} />
                  {emailError && <EmailError>{emailError}</EmailError>} 

                <FormLabel>Modules To Assign</FormLabel>
                  
                    {modules.map((shortName) => (
                      <div key={shortName}>
                        <input
                          type="checkbox"
                          id={shortName}
                          name={shortName}
                          checked={modulesAssigned[shortName] || false}
                          onChange={(e) => handleModuleChange(shortName, e)}
                        />
                        <label htmlFor={shortName}>{modulesMap[shortName]}</label>
                      </div>
                    ))}
                
            </FormColumn>
            <FormColumn className='right-column'>

              <FormLabel>Last Name</FormLabel>
              <Input type="text" value={address} onChange={e => setAddress(e.target.value)} placeholder="Type in" />
              
              <FormLabel>Role</FormLabel>
              <Select value={role} onChange={e => setRole(e.target.value)}>
                <option value="Regular User">Regular User</option>
                <option value="Admin">Admin</option>
              </Select>

              {/* <FormLabel>Assign Roles</FormLabel>
                {Object.keys(selectedRoles).map((role) => (
                  <div key={role}>
                    <input
                      type="checkbox"
                      id={role}
                      checked={selectedRoles[role]}
                      onChange={() => handleRoleChange(role)}
                    />
                    <label htmlFor={role}>{role}</label>
                  </div>
                ))} */}

            </FormColumn>
          </FormRow>
          <ButtonsRow>
            <Button type="submit">Submit</Button>
            <Button type="button" onClick={onClose}>Close</Button>
          </ButtonsRow>
        </ModalForm>
      </Modal>
    </>
  );
}



function OrganizationsPage({ custDomain, onUserAdded }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userDetails } = useUser();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div>
      <Header1>
        <Title>User Management</Title>
        {/* <NewOrganizationButton onClick={toggleModal}>New User</NewOrganizationButton> */}
      </Header1>
      {/* <NewOrganizationModal
        custDomain={custDomain}
        isOpen={modalIsOpen}
        onClose={toggleModal}
        userEmail={userDetails.email}
        onUserAdded={onUserAdded} // Pass the onUserAdded prop to the modal
      /> */}
    </div>
  );
}



const Header1 = styled.header`
display: flex;
justify-content: space-between;
align-items: center;
gap: 10px; // Maintain a reasonable gap between inner elements
padding: 20px; // Adjusted padding for the header
margin-right: -100px; // Ensure this is actually needed, otherwise remove
margin-top: 20px;
width:93%;
@media (max-width: 991px) {
  flex-wrap: wrap;
}
`;


const Title = styled.h1`
margin: 0;
font-size: 30px;
font-family: Ubuntu, sans-serif;
color: var(--bicollab-primary-color-blue-900, #002c57);
`;

const NewOrganizationButton = styled.button`
padding: 10px 18px;
font-size: 20px;
font-family: Ubuntu, sans-serif;
color: var(--bicollab-black-white-white, #fff);
background-color: var(--bicollab-secondary-color-orange-700, #f16622);
border: none;
border-radius: 4px;
cursor: pointer;
`;


function SearchParameterForm({ setSearchResults, recordsToShow }) {
  const { userDetails } = useUser();
  console.log('uss', userDetails)
  const currentUserEmail = userDetails.email;
  const [firstName, setFirstName] = useState('');
  const [isActive, setIsActive] = useState('');

  const handleSearch = () => {

    execute('GET', `${protectedResources.api.baseEndPoint}/customerUsers/searchFellowCustomerUsers?${new URLSearchParams( {
        firstName,
        isActive,
        currentUserEmail      
    })}`)
      .then(response => {
        // Handle the response data as needed
        setSearchResults(response.data);
        console.log('Search Results:', response);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleIsActiveChange = (e) => {
    setIsActive(e);
  };

  return (
    <SearchParameterContainer>
      <SearchParameterTitle>Search Parameter</SearchParameterTitle>
      <SearchParameterFields>

        <InputField
          label="User Name"
          placeholder="Enter a name"
          value={firstName}
          onChange={handleFirstNameChange}
        />
        <SelectField
          label="Status"
          options={[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' }
          ]}
          placeholder="Select Status"
          onChange={handleIsActiveChange}  // Ensure you have a handler for changes
        />

        <SearchButton onClick={handleSearch}>Search</SearchButton>
      </SearchParameterFields>
    </SearchParameterContainer>

  );
}

function SelectField({ label, options, placeholder, onChange }) {
  const handleSelectChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <SelectFieldContainer>
      <SelectFieldLabel>{label}</SelectFieldLabel>
      <SelectWrapper>
        <select onChange={handleSelectChange}>
          <option value="">{placeholder}</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </SelectWrapper>
    </SelectFieldContainer>
  );
}

function InputField({ label, placeholder, value, onChange }) {
  return (
    <InputFieldContainer>
      <InputFieldLabel>{label}</InputFieldLabel>
      <InputFieldWrapper
        type="text"  // Specify the type if necessary, or make it a prop if you need different types
        placeholder={placeholder}
        value={value}  // Bind the input value to the value prop
        onChange={onChange}  // Propagate the onChange event to the parent component
      />
    </InputFieldContainer>
  );
}

const SearchParameterContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch; // Ensures that child components stretch to fill the container width
  border: 1px solid #BBBBBC;
  background-color: #F2F2F2;
  padding: 20px;
  margin: 20px 0; // Adds vertical spacing around the container;
  width: 100%;
  height: 20%;
  margin-top:-10px;
`;


const SearchParameterTitle = styled.h2`
  color: var(--bicollab-primary-color-blue-900, #002c57);
  letter-spacing: 0.4px;
  font: 500 16px/100% Ubuntu, sans-serif;
  
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SearchParameterFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; // Make sure all elements are vertically aligned
  gap: 10px;
  margin-top: 24px;
  
`;



const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #BBBBBC;
  border-radius: 2px;
  padding: 10px 15px;  // Increased padding to add more space inside the box
  width: 90%;  // Ensures the select fills the container

  select {
    border: none;
    width: 100%;
    padding-right: 30px;  // Make room for the icon, adjust this if necessary
    -moz-appearance: none;  // Remove default style Mozilla
    -webkit-appearance: none;  // Remove default style Chrome and Safari
    appearance: none;  // Remove default style
    background: none;
    font-family: Ubuntu, sans-serif;
    font-size: 16px;
    color: #767676;
  }

  &:after {
    content: '▼';  // Adds a custom dropdown icon
    position: absolute;
    right: 15px;  // Adjusted to match the increased padding
    top: 50%;
    transform: translateY(-50%);
    color: #767676;
    pointer-events: none;  // Makes sure clicks on the icon interact with the select
  }
`;


const SelectFieldLabel = styled.label`
  color: #767676;
  text-align: left;
  font: 500 14px Ubuntu, sans-serif;
  padding-bottom: 10px;
   
`;

const SelectFieldWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 1px solid rgba(187, 187, 188, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  margin-top: 13px;
  padding: 10px 21px;
  font-size: 16px;
  color: var(--bicollab-black-white-black-300, #bbbbbc);
  font-weight: 400;
  
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const SelectFieldText = styled.span`
  font-family: Ubuntu, sans-serif;
  margin: auto 0;
`;

const SelectFieldIcon = styled.img`
  width: 24px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
`;

const InputFieldLabel = styled.label`
  color: #767676;
  text-align: left;
  font: 500 14px Ubuntu, sans-serif;
`;

const InputFieldWrapper = styled.input`
  border-radius: 2px;
  border: 1px solid rgba(187, 187, 188, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  margin-top: 13px;
  padding: 10px 21px;
  color: var(--bicollab-black-white-black-300, #bbbbbc);
  font: 400 16px Ubuntu, sans-serif;
  
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const SelectFieldContainer = styled.div`
  flex: 1; // Takes an equal portion of the available space
  display: flex;
  flex-direction: column;
  padding: 0 5px; // Give some space around the fields
  margin top:8px;
`;

const InputFieldContainer = styled.div`
  flex: 1; // Equal flex distribution
  display: flex;
  flex-direction: column;
  padding: 0 5px; // Consistent padding with SelectField
`;

const SearchButton = styled.button`
  border-radius: 4px;
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);
  align-self: flex-end;
  margin-top: 28px;
  padding: 10px 18px;
  color: var(--bicollab-black-white-white, #fff);
  white-space: nowrap;
  font: 500 20px Ubuntu, sans-serif;
  
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;


const StatusButton = styled.span`
  display: inline-block;
  width: 80px;  // Set a fixed width
  height: 25px; // Set a fixed height
  line-height: 25px; // Use line height equal to height for vertical centering
  color: white;
  background-color: ${props => props.active === 'true' ? '#4CAF50' : '#808080'};  // Grey color for inactive
  padding: 0;
  text-align: center; // Center text horizontally
  border-radius: 5px;
  cursor: default;
`;

const Icon = styled.img`
  width: 20px; // Adjust width as needed
  height: 20px; // Adjust height as needed
  margin-right: 5px; // Space between icons
  cursor: pointer;
`;
const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin: 0 2px; // Adds spacing between the buttons
  display: inline-flex; // Ensures the icons align properly
  align-items: center; // Centers the icons vertically
`;
const ActionSeparator = styled.span`
  margin: 0 10px;
  color: #ccc; // Style for the separator
`;

function Data({ custDomain, searchResults, refreshData, setRefreshData }) {
  const { message, model, notification } = App.useApp();
  const { userDetails } = useUser();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);    



  useEffect(() => {
    const fetchRecords = async () => {
      try {
        if(userDetails.email){
          const response = await execute('GET', `${protectedResources.api.baseEndPoint}/customerUsers/fellowCustomerUsers?email=${encodeURIComponent(userDetails.email)}`);
          setRecords(searchResults || response.data || []);
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchRecords();
  }, [searchResults, refreshData, userDetails.email]); // Refetch data when refreshData changes

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  console.log('records:', records);
  const recordsToShow = userDetails ? records.filter(record => record.Email !== userDetails.email) : records;

  const handleEdit = (userId, record) => {
    setCurrentRecord({ ...record, UserId: userId });
    setIsEditModalOpen(true);
  };

  const handleSendInvitation = async (userId, record) => {
    console.log('Send Invitation:', userId, record);
    try {
    var response = await execute('POST', `${protectedResources.api.baseEndPoint}/customerUsers/sendSignupInvitation`, {
      userId: userId
    });
    if(response.success){
      message.success('Invitation sent successfully');
    }else{
      message.error(response.message || 'Failed to send invitation');
    }
    }catch(error){
      message.error('Failed to send invitation: ' + error.message);
    }
  };

  const handleDelete = (userId, record) => {
    setCurrentRecord({ ...record, UserId: userId });
    setDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSaveEditedUser = updatedRecord => {
    setRefreshData(!refreshData); // Trigger data refetch
    setIsEditModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    let newStatus = currentRecord.IsActive ? 'inactive' : 'active';

    try {
      const response = await execute('DELETE', `${protectedResources.api.baseEndPoint}/customerUsers/${currentRecord.UserID}`);
      if (response.success) {
        alert(`User status updated successfully to ${newStatus}.`);
        const updatedRecords = records.map(record =>
          record.UserID === currentRecord.UserID ? { ...record, IsActive: newStatus === 'active' } : record
        );
        setRecords(updatedRecords);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      alert('Failed to modify user: ' + error.message);
    }
    setDeleteModalOpen(false);
    setRefreshData(!refreshData); // Trigger data refetch
  };

  return (
    <DataContainer>
      <DataTable>
        <thead>
          <TableRow>
            <TableHeader>Created On</TableHeader>
            <TableHeader>First Name</TableHeader>
            <TableHeader>Last Name</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Admin</TableHeader>
            <TableHeader>Role Description</TableHeader>
            <TableHeader>Actions</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {recordsToShow.length > 0 ? (
            recordsToShow.map((record, index) => (
              <TableRow key={index}>
                <TableData>{new Date(record.CreatedOn).toLocaleDateString()}</TableData>
                <TableData>{record.FirstName}</TableData>
                <TableData>{record.LastName}</TableData>
                <TableData>{record.Email}</TableData>
                <TableData>{record.IsAdmin ? "Yes" : "No"}</TableData>
                <TableData>
                  <StatusButton active={record.IsActive?'true':'false'}>
                    {record.IsActive ? 'Active' : 'Inactive'}
                  </StatusButton>
                </TableData>
                <TableData>
                  <ActionButton title="Resend the invitation" onClick={(e) => {
                    e.stopPropagation(); // Prevent row click
                    handleSendInvitation(record.UserID, record);
                  }}>
                    <FaEnvelope style={{ color: '#ffad1c' }}/>
                  </ActionButton>
                  <ActionButton onClick={(e) => {
                    e.stopPropagation(); // Prevent row click
                    handleEdit(record.UserID, record);
                  }}>
                    <FaFilePen style={{ color: '#ffad1c' }}/>
                  </ActionButton>
                  <ActionButton onClick={(e) => {
                    e.stopPropagation(); // Prevent row click
                    handleDelete(record.UserID, record);
                  }}>
                    <FaTrash style={{ color: '#ffad1c' }}/>
                  </ActionButton>
                </TableData>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableData colSpan="8">No data available</TableData>
            </TableRow>
          )}
        </tbody>
      </DataTable>
      {isEditModalOpen && <EditUserModal custDomain={custDomain} isOpen={isEditModalOpen} onClose={handleCloseModal} userData={currentRecord} onSave={handleSaveEditedUser} />}
      {isDeleteModalOpen && <DeleteConfirmationModal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)} record={currentRecord} onDeleteConfirmed={handleConfirmDelete} />}
    </DataContainer>
  );
}

const DataContainer = styled.div`
    // height: 400px; /* Set your desired height */
    overflow-y: auto; /* Add vertical scrollbar when content overflows */
    width:100%;
`;

const DataTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;


const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f2f2f2;
    }
    cursor: pointer; /* Changes cursor to indicate clickability */
`;

const TableHeader = styled.th`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  background: white;  // Ensures the header isn't transparent
  position: sticky;
  top: 0;  // Adjust this if your table is not at the very top of the page
  z-index: 1;  // Ensures the header is above other content when scrolling
`;


const TableData = styled.td`
padding: 20px 15px;
border-bottom: 1px solid #ddd;
`;

export default AppLayoutUser;

