import { App, DatePicker, Form, Input, InputNumber, Modal, Select, Space, Switch } from "antd";
import { CloseSquareFilled, SaveFilled } from '@ant-design/icons';
import { useEffect, useState } from "react";
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";

export const DataEditor = ({customer,isOpen, dataItem, onDataEditorFinished, onDataEditorCancelled }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [roles, setRoles] = useState([]);
    const [roleValues, setRoleValues] = useState([]);
    const [role, setRole] = useState(null);

    const handleDataEditFinished = async (values) => {
        console.log("handleDataEditFinished");
        var newData = {...dataItem, ...values};
        console.log(newData);
        
        if(newData.Id){
            console.log("edit")
            const editBody = {...newData, CustomerUserId:dataItem.CustomerUserId}
            // call api to update data
            const response = await execute('PUT',`${protectedResources.api.baseEndPoint}/customeUserRolesValues`, editBody );
            if(response.success){
                message.success("Customer Role Value updated successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        else{
            console.log("add")
            // call  api to add data
            const response = await execute('POST',`${protectedResources.api.baseEndPoint}/customeUserRolesValues`, newData);
            if(response.success){   
                message.success("Customer Role Value added successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        onDataEditorFinished(newData);
    }

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        onDataEditorCancelled();
    }

    const fetchRoleValue = async (RoleId) => {
        console.log("RoleId///////////////")
        console.log(RoleId)
        try {
            setLoading(true);
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/customerRoles/${RoleId}/values`);
            console.log('response', response);
            if (response.success && response.data.length > 0) {
                const transformedData = response.data.map(item => ({
                    value: item.Id,
                    label: item.Value
                }));
                setRoleValues(transformedData);
                setError('');
            } else {
                setRoleValues([]);
                setError(response.message || 'No customer role value found for the specified organization');
            }
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };
    

    const fetchRoles = async () => {
        try {
            setLoading(true);
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/customers/${customer.CustomerID}/customerRoles`);
            console.log('response', response);
            if (response.success && response.data.length > 0) {
                
                const transformedData = response.data.map(item => ({
                    value: item.Id,
                    label: item.Name
                }));
                setRoles(transformedData);
                setError('');
            } else {
                setRoles([]);
                setError(response.message || 'No customers found for the specified organization');
            }
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log(dataItem)
        console.log(customer)
        if(customer){
            fetchRoles()
        }
        if(dataItem.Id){
            fetchRoleValue(dataItem.RoleId)
        }
        if(isOpen){
            const updated = {...dataItem, CustomerRoleId: dataItem.RoleId}
            form.setFieldsValue(updated);
        }
    }, [isOpen, dataItem, form]);

    return (
        <Modal
            open={isOpen}
            title={dataItem.Id ? "Edit CustomerUser RoleValue" : "Add CustomerUser RoleValue"}
            okText={<Space><SaveFilled />Save</Space> }
            cancelText={<Space><CloseSquareFilled />Cancel</Space> }
            okButtonProps={{ autoFocus: true, htmlType: 'submit' }} //form: 'dataEditorForm', key: 'submit'
            onCancel={handleDataEditCancelled}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                  layout="horizontal"
                  form={form}
                  name="dataEditorForm"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{
                    modifier: 'public',
                  }}
                  clearOnDestroy
                  onFinish={handleDataEditFinished}
                >
                  {dom}
                </Form>
            )}>            
            {/* <Form.Item
                name="Id"
                label="Id"
                hidden>
                <Input />
            </Form.Item> */}
            <Form.Item
                name="CustomerUserId"
                label="CustomerUserId"
                hidden
                // rules={[{ required: true, message: 'Customer Role Value is required' }]}
                >
                <Input value={dataItem.Id ? dataItem.CustomerUserId : dataItem.UserId }/>
            </Form.Item>

            <Form.Item
                name="CustomerRoleId"
                label="Select Role"
                rules={[{ required: true, message: 'Customer Role is required' }]}
                >
                <Select
                    defaultValue={dataItem.Id ? dataItem.RoleName : null}
                    style={{ width: 120 }}
                    loading = {loading ? true : false}
                    options={roles}
                    placeholder="select it"
                    onChange={ async (value)=> await fetchRoleValue(value)}
                />
            </Form.Item>

            <Form.Item
                name="CustomerRoleValueId"
                label="Select Role Value"
                rules={[{ required: true, message: 'Customer Role Value is required' }]}
                >
                <Select
                    defaultValue={dataItem.Id ? dataItem.RoleValues : null}
                    style={{ width: 120 }}
                    loading = {loading ? true : false}
                    options={roleValues}
                    placeholder="select it"
                />
            </Form.Item>

            {/* <Form.Item
                name="Status"
                label="Enable"
            >
                <Switch defaultChecked onChange={(checked)=>console.log(checked)} />

            </Form.Item> */}
        </Modal>
    );
};