import React, { useState,useEffect,useMemo } from 'react';

import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import execute from '../hooks/execute';
import { protectedResources } from '../config/msalConfig';
import { DatePicker } from "antd";

function AdditionalActions() {   
  return (
    <Columnn>
      <NewCitation />
      <NavigationBar/>
      <AuditForm />
    </Columnn>
  );
}

const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  width: 110%;
  gap: 20px; // Adjust the vertical gap between MyComponent1 and MyComponent3
`;



function NewCitation() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { userDetails } = useUser();
  
    const toggleModal = () => {
      setModalIsOpen(!modalIsOpen);
    };
  
    return (
      <div>
        <Header1>
          <Title>New Citation</Title>
          {/* <NewOrganizationButton onClick={toggleModal}>New User</NewOrganizationButton> */}
  
        </Header1>
              {/* <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} userEmail={userDetails.email}  /> */}
  
      </div>
    );
  }
  
  
  const Header1 = styled.header`
  display: flex;
  padding: 10px; // Adjusted padding for the header
  margin-right: -100px; // Ensure this is actually needed, otherwise remove
  margin-top: 20px;
  margin-left:-170px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  `;
  
  
  const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-primary-color-blue-900, #002c57);
  `;
  
  const NewCitation1 = styled.button`
  padding: 10px 18px;
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-black-white-white, #fff);
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 790px;
  `;


//   const NavigationItem = ({ text, isActive }) => (
//     <NavItem isActive={isActive}>{text}</NavItem>
//   );
  
//   function NavigationBar() {
//     const navItems = [
//       { text: "Citation", isActive: true },
//       { text: "Provider Plan of Action", isActive: false },
//       { text: "Additional Corrective Actions", isActive: false },
//       { text: "Review", isActive: false },
//     ];
  
//     return (
//         <SearchContainer>
//       <nav className="navigation-bar">
//         {navItems.map((item, index) => (
//           <NavigationItem key={index} text={item.text} isActive={item.isActive} />
//         ))}
//       </nav>
//      </SearchContainer>
//     );
//   }
// Component for each navigation item
const NavigationItem = ({ text, isActive, onClick }) => (
    <NavItem isActive={isActive} onClick={onClick}>
      {text}
    </NavItem>
  );
  
  // Navigation bar component with dynamic active state management
  function NavigationBar() {
    const [activeIndex, setActiveIndex] = useState(0); // State to track the active item
    const [displayText, setDisplayText] = useState(""); // State to track text to display when item is clicked
    const navigate = useNavigate();

    const navItems = [
      { text: "Citation", route:"/newcitation"},
      { text: "Provider Plan of Action",route: "/provider-plan"  },
      { text: "Additional Corrective Actions",route: "/additional-action"},
      { text: "Review" },
    ];
    const handleItemClick = (index, text, route) => {
        setActiveIndex(index);
        setDisplayText(`You clicked on: ${text}`);
        navigate(route);
      };
    // const handleItemClick = (index, text) => {
    //   setActiveIndex(index);  // Update the active index to the clicked item's index
    //   setDisplayText(`You clicked on: ${text}`);  // Update the display text
    // };
  
    return (
        <SearchContainer>
        <nav className="navigation-bar">
          {navItems.map((item, index) => (
            <NavigationItem
              key={index}
              text={item.text}
              isActive={index === activeIndex}  // Determine if the item is active based on index
            //   onClick={() => handleItemClick(index, item.text)}
            onClick={() => handleItemClick(index, item.text, item.route)}

            />
          ))}
        </nav>
        <div>{displayText}</div> {/* Display text area */}
      </ SearchContainer>
    );
  }
  
  const SearchContainer = styled.section`
  border-radius: 3px;
  background-color: var(--bicollab-black-white-black-100, #f2f2f2);
  display: flex;
  gap: 20px;
  margin-top:-20px;
  margin-left: -160px;
  margin-right: -120px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  padding: 6px 14px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;
//   const NavItem = styled.button`
//     font-family: Ubuntu, sans-serif;
//     font-size: 14px;
//     font-weight: 500;
//     letter-spacing: 0.4px;
//     line-height: 114%;
//     border-radius: 4px;
//     background-color: var(--bicollab-black-white-white, #fff);
//     color: ${props => props.isActive ? 'var(--bicollab-secondary-color-orange-700, #f16622)' : 'var(--bicollab-black-white-black-500, #515151)'};
//     padding: 10px;
//     border: none;
//     cursor: pointer;
  
//     @media (max-width: 991px) {
//       white-space: normal;
//     }
//   `;

const NavItem = styled.button`
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  border-radius: 4px; // Rounded corners for a smooth box appearance
  background-color: var(--bicollab-black-white-white, #fff); // Light background
  color: ${props => props.isActive ? 'var(--bicollab-secondary-color-orange-700, #f16622)' : 'var(--bicollab-black-white-black-500, #515151)'};
  padding: 10px 15px; // Slightly increased padding for better visual spacing
  border: 2px solid var(--bicollab-black-white-grey-300, #ddd); // Visible border to emphasize the box
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1); // Subtle shadow for a 3D effect
  cursor: pointer;
  transition: all 0.3s ease; // Smooth transition for hover effects
  margin-right: 10px;
  &:hover {
    background-color: var(--bicollab-grey-200, #f7f7f7); // Change background on hover
    box-shadow: 0px 4px 8px rgba(0,0,0,0.15); // Increase shadow on hover for a "lifted" effect
  }

  @media (max-width: 991px) {
    white-space: normal; // Ensures text wraps properly in smaller screens
  }
`;

  const StyledNavigationBar = styled.nav`
    border-radius: 3px;
    background-color: var(--bicollab-black-white-black-100, #f2f2f2);
    display: flex;
    gap: 12px;
    padding: 6px 14px;
  
    @media (max-width: 991px) {
      flex-wrap: wrap;
      padding-right: 20px;
    }
  `;

  const AuditForm = () => {
    const [formData, setFormData] = useState({
      auditDate: new Date(),
      auditAgency: '',
      auditType: 'Licensing',
      status: 'In Process',
      reviewer: '',
      additionalReviewer: '',
      citationType: 'Individual Record',
      customerName: '',
      location: '',
      regulationNumberDesc: '',
      correctiveActionType: '',
      correctiveActionCategory: '',
      correctiveActionDueDate: new Date(),
      responsibleParty: '',
      correctiveActionCompletionDate: new Date(),
      describeCitation: '',
      correctiveActionRequired: '',
      comments: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
  
    const handleDateChange = (name, date) => {
      setFormData((prev) => ({ ...prev, [name]: date }));
    };
  
    const handleSave = async () => {
      console.log('Save clicked');
      console.log(formData);
    };
  
    return (
      <FormContainer>
        <SectionHeader>
          <SectionTitle>Primary Information</SectionTitle>
        </SectionHeader>
  
        <InfoGrid>
          <InfoItem>
            <FieldLabel>Audit Date</FieldLabel>
            <DatePicker selected={formData.auditDate} onChange={(date) => handleDateChange('auditDate', date)} />
          </InfoItem>
          <InfoItem>
            <FieldLabel>Audit Agency</FieldLabel>
            <InputField name="auditAgency" value={formData.auditAgency} onChange={handleChange} placeholder="Enter agency name" />
          </InfoItem>
          <InfoItem>
            <FieldLabel>Audit Type</FieldLabel>
            <select name="auditType" value={formData.auditType} onChange={handleChange}>
              <option value="Licensing">Licensing</option>
              <option value="Option 1">Option 1</option>
              <option value="Option 2">Option 2</option>
            </select>
          </InfoItem>
          <InfoItem>
            <FieldLabel>Status</FieldLabel>
            <select name="status" value={formData.status} onChange={handleChange}>
              <option value="In Process">In Process</option>
              <option value="Option 1">Option 1</option>
              <option value="Option 2">Option 2</option>
            </select>
          </InfoItem>
        </InfoGrid>
  
        <ReviewerSection>
          <InfoItem>
            <FieldLabel>Reviewer</FieldLabel>
            <InputField name="reviewer" value={formData.reviewer} onChange={handleChange} placeholder="Enter reviewer name" />
          </InfoItem>
          <InfoItem>
            <FieldLabel>Additional Reviewer</FieldLabel>
            <InputField name="additionalReviewer" value={formData.additionalReviewer} onChange={handleChange} placeholder="Enter additional reviewer name" />
          </InfoItem>
        </ReviewerSection>
  
        <Divider />
  
        <SectionHeader>
          <SectionTitle>Secondary Information</SectionTitle>
        </SectionHeader>
  
        <InfoGrid>
          <InfoItem>
            <FieldLabel>Citation Type</FieldLabel>
            <select name="citationType" value={formData.citationType} onChange={handleChange}>
              <option value="Individual Record">Individual Record</option>
              <option value="Option 1">Option 1</option>
              <option value="Option 2">Option 2</option>
            </select>
          </InfoItem>
          <InfoItem>
            <FieldLabel>Customer Name</FieldLabel>
            <InputField name="customerName" value={formData.customerName} onChange={handleChange} placeholder="Enter customer name" />
          </InfoItem>
          <InfoItem>
            <FieldLabel>Location</FieldLabel>
            <InputField name="location" value={formData.location} onChange={handleChange} placeholder="Enter location" />
          </InfoItem>
        </InfoGrid>
  
        <InfoText>Input the individual who's record is being reviewed, if applicable</InfoText>
  
        <Divider />
  
        <SectionHeader>
          <SectionTitle>Additional Information</SectionTitle>
        </SectionHeader>
  
        <InfoGrid>
          <InfoItem>
            <FieldLabel>Regulation Number - Desc</FieldLabel>
            <InputField name="regulationNumberDesc" value={formData.regulationNumberDesc} onChange={handleChange} placeholder="Enter regulation number" />
          </InfoItem>
          <InfoItem>
            <FieldLabel>Corrective Action Type</FieldLabel>
            <select name="correctiveActionType" value={formData.correctiveActionType} onChange={handleChange}>
              <option value="Type A">Type A</option>
              <option value="Option 1">Option 1</option>
              <option value="Option 2">Option 2</option>
            </select>
          </InfoItem>
          <InfoItem>
            <FieldLabel>Corrective Action Category</FieldLabel>
            <select name="correctiveActionCategory" value={formData.correctiveActionCategory} onChange={handleChange}>
              <option value="Category 1">Category 1</option>
              <option value="Option 1">Option 1</option>
              <option value="Option 2">Option 2</option>
            </select>
          </InfoItem>
          <InfoItem>
            <FieldLabel>Corrective Action Due Date</FieldLabel>
            <DatePicker selected={formData.correctiveActionDueDate} onChange={(date) => handleDateChange('correctiveActionDueDate', date)} />
          </InfoItem>
          <InfoItem>
            <FieldLabel>Responsible Party</FieldLabel>
            <InputField name="responsibleParty" value={formData.responsibleParty} onChange={handleChange} placeholder="Enter responsible party" />
          </InfoItem>
          <InfoItem>
            <FieldLabel>Corrective Action Completion Date</FieldLabel>
            <DatePicker selected={formData.correctiveActionCompletionDate} onChange={(date) => handleDateChange('correctiveActionCompletionDate', date)} />
          </InfoItem>
        </InfoGrid>
  
        <TextAreaSection>
          <TextAreaColumn>
            <FieldLabel>Describe Citation/Violation of Regulation</FieldLabel>
            <TextArea name="describeCitation" value={formData.describeCitation} onChange={handleChange} placeholder="Type in" />
          </TextAreaColumn>
          <TextAreaColumn>
            <FieldLabel>Corrective Action Required By Inspector</FieldLabel>
            <TextArea name="correctiveActionRequired" value={formData.correctiveActionRequired} onChange={handleChange} placeholder="Type in" />
          </TextAreaColumn>
        </TextAreaSection>
  
        <FieldLabel>Comments/Follow-up Comments</FieldLabel>
        <TextArea name="comments" value={formData.comments} onChange={handleChange} placeholder="Type in" />
  
        <ButtonGroup>
          <CancelButton type="button">Cancel</CancelButton>
          <SaveButton type="button" onClick={handleSave}>Save</SaveButton>
          <FinalizeButton type="button">Finalize</FinalizeButton>
        </ButtonGroup>
      </FormContainer>
    );
  };
  
  // const FormContainer = styled.form`
  //   border-radius: 2px;
  //   display: flex;
  //   flex-direction: column;
  //   padding: 17px 11px;
  // `;
  
//   const FormContainer = styled.form`
//   border-radius: 2px;
//   display: flex;
//   flex-direction: column;
//   padding: 17px 11px;
//   width: 100%;
//   max-width: 1800px; // Set a maximum width for the form container
//   margin: 0 auto; // Center the form container
//   box-sizing: border-box;
//   overflow-y: auto; // Enables vertical scrolling if content overflows
//   max-height: 70vh; 
//   @media (max-width: 991px) {
//     padding: 10px;
//   }

//   @media (max-width: 767px) {
//     padding: 8px;
//   }

//   @media (max-width: 480px) {
//     padding: 5px;
//   }
// `;


  const SectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 114%;
    margin-bottom: 20px;
  
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  `;
  
  const SectionTitle = styled.h2`
    color: #002c57;
    font-family: Ubuntu, sans-serif;
    margin: 0;
  `;
  
  // const InfoGrid = styled.div`
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 20px;
  //   justify-content: space-between;
  //   margin-bottom: 30px;
  
  //   @media (max-width: 991px) {
  //     flex-direction: column;
  //   }
  // `;
  
  const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
  `;
  
  const FieldLabel = styled.label`
    color: #767676;
    text-align: center;
    font: 500 14px Ubuntu, sans-serif;
    margin-bottom: 13px;
  `;
  
  const InputField = styled.input`
    border-radius: 2px;
    border: 1px solid #bbbbbc;
    background-color: #fff;
    padding: 10px 21px;
    font: 400 16px Ubuntu, sans-serif;
    color: #1c1c1c;
  
    @media (max-width: 991px) {
      padding: 10px;
    }
  `;
  
  const ReviewerSection = styled.section`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 30px;
  
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  `;
  
  const Divider = styled.hr`
    width: 100%;
    border: none;
    border-top: 1px solid #767676;
    margin: 20px 0;
  `;
  
  const InfoText = styled.p`
    color: #515151;
    font: 400 16px Ubuntu, sans-serif;
    margin: 20px 0;
  
    @media (max-width: 991px) {
      width: 100%;
    }
  `;
  
  const TextAreaSection = styled.section`
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  
    @media (max-width: 991px) {
      flex-direction: column;
    }
  `;
  
  const TextAreaColumn = styled.div`
    flex: 1;
  
    @media (max-width: 991px) {
      width: 100%;
    }
  `;
  
  const TextArea = styled.textarea`
    width: 100%;
    border-radius: 2px;
    border: 1px solid #bbbbbc;
    background-color: #fff;
    padding: 19px 21px;
    font: 400 16px Ubuntu, sans-serif;
    resize: vertical;
    min-height: 100px;
  
    @media (max-width: 991px) {
      padding: 19px;
    }
  `;
  
  // const ButtonGroup = styled.div`
  //   display: flex;
  //   gap: 13px;
  //   margin-top: 58px;
  
  //   @media (max-width: 991px) {
  //     margin-top: 40px;
  //     flex-wrap: wrap;
  //   }
  // `;
  
  // const Button = styled.button`
  //   font-family: Ubuntu, sans-serif;
  //   font-size: 20px;
  //   font-weight: 500;
  //   border-radius: 50px;
  //   padding: 10px 18px;
  //   cursor: pointer;
  
  //   @media (max-width: 991px) {
  //     padding: 10px;
  //     width: 100%;
  //   }
  // `;
  
//   const CancelButton = styled(Button)`
//     color: #f16622;
//     background-color: #fff;
//     border: 1px solid #f16622;
//   `;
  
//   const SaveButton = styled(Button)`
//     color: #fff;
//     background-color: #f16622;
//     border: none;
//   `;
  
//   const FinalizeButton = styled(Button)`
//     color: #f16622;
//     background-color: #fff;
//     border: 1px solid #f16622;
//   `;
  

const FormContainer = styled.form`
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 17px 11px;
  // margin-left: 20px;
  margin: 0 auto; // Center the form container
  // box-sizing: border-box;
  width:120%;
  margin-left: -150px;
  overflow-y: auto; // Enables vertical scrolling if content overflows
  max-height: 65vh; 

`;

const InfoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const InfoItemWrapper = styled.div`
  flex: 1 1 45%; // Ensure items take up 45% width of the container
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const InfoLabel = styled.label`
  color: #767676;
  text-align: center;
  font: 500 14px Ubuntu, sans-serif;
  margin-bottom: 13px;
`;

const InfoValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 1px solid #bbbbbc;
  background-color: #fff;
  padding: 10px 21px;
  font: 400 16px Ubuntu, sans-serif;
  color: #1c1c1c;

  img {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 991px) {
    padding: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 13px;
  margin-top: 58px;

  @media (max-width: 991px) {
    margin-top: 40px;
    flex-wrap: wrap;
  }
`;

const Button = styled.button`
  font-family: Ubuntu, sans-serif;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50px;
  padding: 10px 18px;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 10px;
    width: 100%;
  }
`;

const CancelButton = styled(Button)`
  color: #f16622;
  background-color: #fff;
  border: 1px solid #f16622;
`;

const SaveButton = styled(Button)`
  color: #fff;
  background-color: #f16622;
  border: none;
`;

const FinalizeButton = styled(Button)`
  color: #f16622;
  background-color: #fff;
  border: 1px solid #f16622;
`;

  
export default AdditionalActions;