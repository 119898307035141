// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer {
    transition: right 0.3s;
}

.drawer.open {
    right: 0;
}

.drawer.open h2{
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/views/Feedback/feedback.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,SAAS;AACb","sourcesContent":[".drawer {\n    transition: right 0.3s;\n}\n\n.drawer.open {\n    right: 0;\n}\n\n.drawer.open h2{\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
