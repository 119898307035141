import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import UserDataService from '../services/localStorageService';
import execute from '../hooks/execute';
import { protectedResources } from '../config/msalConfig';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
 
  const { instance } = useMsal();
  let account;

  if (instance) {
      account = instance.getActiveAccount();
  }



  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
          if(userDetails.email === account.idTokenClaims.email){
            return;
          }
          //console.log('fetchUserDetails from UserContext', account.idTokenClaims.email);
          const response = await execute("GET", `${protectedResources.api.baseEndPoint}/customerUsers/currnetUserData`);
          if (response && response.success && response.data.IsActive) {
              UserDataService.setUserData(response.data);
              setUserDetails({...response.data,
                name: account.name,
                email: account.idTokenClaims.email,
                id: account.localAccountId,
              });
          } else {
              alert( response.message || 'You are not allowed to log in.');
              UserDataService.clearUserData(); // Clear user data from local storage
              instance.logoutRedirect({ postLogoutRedirectUri: window.location.origin });
          }
      } catch (error) {
          console.error(`Error verifying user: ${account.idTokenClaims.email}`, error);
          UserDataService.clearUserData(); // Clear user data from local storage
          instance.logoutRedirect({ postLogoutRedirectUri: window.location.origin });
      }
    };
      //console.log('setting userDetails from account');
      if(account && account.idTokenClaims && account.idTokenClaims.email){
        fetchUserDetails();
      }else{
        //console.log("No active account found", account);
      }     
    
  },[account, instance]);

  return (
    <UserContext.Provider value={{ userDetails }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
